import React, { useContext } from "react";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import InputWithCopy from "../../../settings/components/InputWithCopy";
import CustomDomainContext from "./CustomDomainContext";
import StyledChip from "../../../../styles/components/Chip.Styled";
import { getCustomDomainStatusChipColor } from "../../../../utils/uiUtils.ts";

function ShowDNSInstructionsForNameserversMethod() {
    const { domain } = useContext(CustomDomainContext);

    return (
        <Box className="NSDetailsContainer">
            <Box className="LabelContainer">
                <h2>Update nameservers in your domain host account</h2>
            </Box>
            <Box className="UserInstuctionsContainer">
                To connect your domain, you must update your domain
                {"\u0027"}s nameserver settings in your domain host account
                (usually the company you bought your domain from).
            </Box>

            <Box>
                <ul className="NSList">
                    {domain.ns_details.map((record) => (
                        <li>
                            <InputWithCopy
                                textToCopy={record.slice(0, -1)}
                                input={<div>{record}</div>}
                            />
                        </li>
                    ))}
                </ul>

                <Box className="Examples">
                    See instructions below to change nameservers on popular
                    registrars and hosting providers:
                    <ul>
                        <li>
                            <a
                                href="https://www.namecheap.com/support/knowledgebase/article.aspx/767/10/how-to-change-dns-for-a-domain/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Changing Your Nameservers with Namecheap
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://il.godaddy.com/en/help/edit-my-domain-nameservers-664"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Changing Your Nameservers with GoDaddy
                            </a>
                        </li>
                    </ul>
                </Box>

                <Box className="DNSTableContainer">
                    <h1>SSL certification status</h1>
                    <TableContainer
                        component={Paper}
                        className="DNSTable CertificateTable"
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Domain</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell scope="row">
                                        {domain.name}
                                    </TableCell>
                                    <TableCell scope="row">
                                        <StyledChip
                                            className="Capitalize"
                                            label={domain.certificate.status}
                                            color={getCustomDomainStatusChipColor(
                                                domain.certificate.status
                                            )}
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box className="Notes">
                    <h3>Notes:</h3>
                    <Box>
                        Your new certificate might continue to display a status
                        of{" "}
                        <span className="font-style-italic">
                            Pending Validation
                        </span>{" "}
                        for up to 30 minutes.
                    </Box>
                    <Box>
                        DNS server update may take up to 48 hours to take
                        effect.
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default ShowDNSInstructionsForNameserversMethod;
