import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";

const StyledAutoCompleteInput = styled(Autocomplete)(({ theme }) => ({
    "& .MuiInputBase-root": {
        padding: 0,
    },
}));

export default StyledAutoCompleteInput;
