import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import InputWithControls from "../InputWithControls";

function CellWithInput({
    keyToUpdate,
    rowIndex,
    colIndex,
    value,
    customInputActions,
    onValueUpdate,
}) {
    return (
        <InputWithControls
            placeholder="Param value"
            value={value}
            onValueUpdate={(newValue) => {
                onValueUpdate(newValue, rowIndex, { keyToUpdate });
            }}
            customInputControls={customInputActions}
        />
    );
}

CellWithInput.propTypes = {
    rowIndex: PropTypes.number.isRequired,
    colIndex: PropTypes.number,
    keyToUpdate: PropTypes.string,
    value: PropTypes.string,
    onValueUpdate: PropTypes.func.isRequired,
    customInputActions: PropTypes.arrayOf(PropTypes.shape),
};

CellWithInput.defaultProps = {
    value: "",
    keyToUpdate: undefined,
    colIndex: undefined,
    customInputActions: [],
};

export default CellWithInput;
