import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
} from "@mui/material";
import { ActionButton } from "./UI";
import ploomberAPI from "../services/ploomberAPI.ts";
import { AppContext } from "../context/AppContext";
import { parseErrorMessage } from "../utils/utils.ts";

function DeleteCustomDomainDialog({
    domainName,
    open,
    onClose,
    onDelete,
    domains,
}) {
    const [openDialog, setOpenDialog] = useState(open);
    const [isLoading, setIsLoading] = useState(false);
    const { updateSnackbarStatus } = useContext(AppContext);

    const handleDeleteCustomDomainClick = async () => {
        try {
            setIsLoading(true);
            await ploomberAPI.deleteCustomDomain(domainName);
            updateSnackbarStatus({
                message: `${domainName} deleted successfully`,
            });
            setIsLoading(false);
        } catch (err) {
            updateSnackbarStatus({
                message: parseErrorMessage(err),
                severity: "error",
            });
            setIsLoading(false);
        } finally {
            if (onDelete) {
                onDelete();
            }
        }
    };

    useEffect(() => {
        setOpenDialog(open);
    }, [open]);

    return (
        <Dialog
            id="deleteCustomDialog"
            open={openDialog}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            style={{
                height: "60%",
            }}
        >
            <DialogTitle id="responsive-dialog-title">
                Delete <strong>{domainName}</strong>
            </DialogTitle>
            <DialogContent>
                <DialogContentText component="div" color="#000">
                    <div style={{ marginTop: 20 }}>
                        Are you sure you want to delete this domain?
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    id="closeDialogButton"
                    onClick={onClose}
                    variant="outline"
                >
                    Cancel
                </ActionButton>

                <ActionButton
                    id="deleteCustomDomainButton"
                    onClick={handleDeleteCustomDomainClick}
                    variant="contained"
                    color="error"
                    disabled={isLoading}
                    disabledOptions={
                        isLoading
                            ? {
                                  startIcon: (
                                      <CircularProgress
                                          size={10}
                                          color="neutral"
                                      />
                                  ),
                                  text: "Deleting...",
                              }
                            : {}
                    }
                >
                    Delete
                </ActionButton>
            </DialogActions>
        </Dialog>
    );
}

DeleteCustomDomainDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    domainName: PropTypes.string.isRequired,
    domains: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DeleteCustomDomainDialog;
