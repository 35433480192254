import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledController = styled(Box)(({ theme }) => ({
    "& .InputWithButtonContainer": {
        width: "70%",
        display: "flex",
        gap: theme.spacing(2),
    },

    "& .InputWithButtonContainer .InputContainer": {
        width: "70%",
    },

    "& .ControllerItem": {
        marginBottom: theme.spacing(5),
    },

    "& .RegisteredDomainsContainer": {
        display: "inline-flex",
        gap: theme.spacing(2),
    },

    "& .RefreshButtonContainer": {
        margin: "auto 0",
    },

    "& .RefreshingContainer": {
        margin: theme.spacing(2),
    },
}));

export default StyledController;
