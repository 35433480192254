import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";

const StyledAppBar = styled(AppBar)(({ theme, open }) => ({
    backgroundColor: theme.palette.neutral.white,
    width: `calc(100% - ${
        open ? theme.app.drawerWidth : theme.app.closedDrawerWidth
    }px)`,
    ml: `${open ? theme.app.drawerWidth : theme.app.closedDrawerWidth}px`,
    "& a": {
        textDecoration: "none",
        color: theme.palette.neutral.black,
        textTransform: "capitalize",
    },
}));

export default StyledAppBar;
