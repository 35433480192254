import React, { createContext, useMemo, useState } from "react";

const ProjectContext = createContext({
    resourcesInfo: null,
    setResourcesInfo: () => {},
});

// this context provides the details for the currently selected project in the CreateApplication component
function ProjectContextProvider(props) {
    const [resourcesInfo, setResourcesInfo] = useState();
    const projectProviderMemo = useMemo(
        () => ({
            resourcesInfo,
            setResourcesInfo,
        }),
        [resourcesInfo, setResourcesInfo]
    );
    //
    // eslint-disable-next-line react/prop-types
    const { children } = props;

    return (
        <ProjectContext.Provider value={projectProviderMemo}>
            {children}
        </ProjectContext.Provider>
    );
}

export { ProjectContext, ProjectContextProvider };
