import React from "react";
import PropTypes from "prop-types";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledBlockTable from "../../styles/components/Block/BlockTable.Styled";

export default function BlockTable({ keys, values, emptyTablePlaceholder }) {
    const defaultTableColWidth = `${100 / keys.length}%`;
    const defaultCellStyle = {
        width: defaultTableColWidth,
    };
    return (
        <TableContainer component={Paper} elevation={0}>
            <StyledBlockTable sx={{ width: "100%" }}>
                <TableHead>
                    <TableRow>
                        {keys.map((key, index) => (
                            <TableCell
                                sx={key.style || defaultCellStyle}
                                align="left"
                                key={key.title || index}
                            >
                                {key.title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {values.length === 0 ? (
                        <div style={{ textAlign: "center" }}>
                            {emptyTablePlaceholder}
                        </div>
                    ) : (
                        values.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {row.map((col, colIndex) => (
                                    <TableCell
                                        key={colIndex}
                                        align="left"
                                        style={{ padding: 0, width: "100%" }}
                                    >
                                        {col}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </StyledBlockTable>
        </TableContainer>
    );
}

BlockTable.propTypes = {
    keys: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.shape),
    ]).isRequired,
    values: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.shape,
                PropTypes.string,
                PropTypes.element,
                PropTypes.func,
            ])
        )
    ).isRequired,
    emptyTablePlaceholder: PropTypes.string,
};

BlockTable.defaultProps = {
    emptyTablePlaceholder: "No values",
};
