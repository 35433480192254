import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const margin = 10;

const StyledTerminal = styled(Box)(({ theme }) => ({
    color: theme.palette.neutral?.grey,
    "&": {
        backgroundColor: "#000",
        color: "#fff",
        height: 300,
        padding: "5px 10px",
        fontFamily: "Fira Code",
        whitespace: "pre-wrap",
        maxWidth: "100%",
        overflowY: "auto",
        overflowX: "hidden",
    },
    ".Row": {
        marginTop: margin,
        marginBottom: margin,
    },
}));

export default StyledTerminal;
