import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "&": {
        height: "60%",
    },
}));

export default StyledDialog;
