import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const paddingLeft = 10;
const paddingRight = 10;
const StyledBlock = styled(Box)(({ theme, border }) => ({
    width: "80%",
    border:
        border?.toString() || `1px solid ${theme.palette.neutral?.darkGrey}`,
    backgroundColor: theme.palette.neutral?.white,
    borderRadius: 5,
    marginBottom: 50,
    marginLeft: "auto",
    marginRight: "auto",
    flexGrow: 1,
    "& .Header": {
        backgroundColor: theme.palette.neutral?.lightGrey,
        paddingLeft,
        paddingTop: 10,
        paddingBottom: 5,
        alignItems: "center",
        borderRadius: "inherit",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
        display: "flex",
    },
    "& .Header.closed": {
        borderRadius: "inherit",
        borderBottomLeftRadius: "inherit",
        borderBottomRightRadius: "inherit",
    },
    "& .Header .TitleWrapper": {
        display: "flex",
        gap: 1,
        width: "100%",
    },
    "& .Header .Description": {
        fontSize: theme.fonts?.size.small,
        marginLeft: "2.2em",
        marginTop: 5,
        marginBottom: 0,
    },
    "& .Header .subscribe-label": {
        fontSize: theme.fonts?.size.small,
    },
    "& svg": {
        display: "flex",
        alignItems: "center",
    },
    "& .Header .Title": {
        width: "50%",
    },
    "& .Header .Actions": {
        paddingRight,
        width: "50%",
        textAlign: "right",
        margin: "auto",
    },
    "& .Content": {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: paddingLeft * 4.3,
        paddingRight: paddingRight * 4.3,
    },
    "& .Content *": {
        fontSize: theme.fonts?.size.small,
    },
    "& .Content label .label": {
        color: theme.palette.neutral?.black,
    },
}));

export default StyledBlock;
