import React from "react";
import PropTypes from "prop-types";
import { DialogActions, DialogContent } from "@mui/material";
import { ActionButton } from "../UI";
import StyledDialog from "../../styles/components/UI/Dialog.Styled";

function ValidateDowngradeDialog({ open, onClose, onValidateClick }) {
    const handleDowngradeClick = () => {
        onValidateClick();
        setTimeout(() => {
            onClose();
        }, 100);
    };

    return (
        <StyledDialog
            className="ValidateDowngradeDialog"
            onClose={onClose}
            open={open}
        >
            <DialogContent>
                Are you sure you want to downgrade your subscription plan?
            </DialogContent>

            <DialogActions>
                <ActionButton
                    id="closeDialogButton"
                    onClick={onClose}
                    variant="outline"
                >
                    Cancel
                </ActionButton>

                <ActionButton
                    id="approveDowngradeButton"
                    onClick={handleDowngradeClick}
                    variant="contained"
                    color="error"
                >
                    Downgrade
                </ActionButton>
            </DialogActions>
        </StyledDialog>
    );
}

ValidateDowngradeDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onValidateClick: PropTypes.func,
};

ValidateDowngradeDialog.defaultProps = {
    open: false,
    onClose: () => {},
    onValidateClick: () => {},
};

export default ValidateDowngradeDialog;
