import PropTypes from "prop-types";

// this function will be run on each key, val row for CPU and RAM to see if the price should be displayed or not
function shouldDisplayPricing(type, hasApps, value) {
    switch (type) {
        // define your rules here
        case "cpu":
            return hasApps || Number(value) > 1;
        case "ram":
            return hasApps || Number(value) > 2;
        default:
            return true;
    }
}

shouldDisplayPricing.propTypes = {
    hasApp: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(["cpu", "ram"]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default shouldDisplayPricing;
