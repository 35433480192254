import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledJobStatus = styled(Box)(({ theme }) => ({
    "&": {
        marginTop: theme.spacing(2),
    },
    "& .Top": {
        display: "inline-flex",
        width: "100%",
    },
    "& .JobStepperContainer": {
        width: "60%",
        margin: "auto",
        marginTop: theme.spacing(5),
        textAlign: "left",
        marginLeft: theme.spacing(-1),
        padding: "5px 0",
    },

    "& .ActionsContainer": {
        textAlign: "right",
        width: "50%",
        marginRight: theme.spacing(1),
    },

    "& .AuthenticationContainer": {
        marginTop: 3,
    },

    "& .AuthenticationContainer .InputContainer": {
        width: "100%",
    },

    "& .AuthenticationContainer .InputWithCopy": {
        marginBottom: 3,
        width: "100%",
    },
}));

export default StyledJobStatus;
