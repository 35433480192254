import React, { useContext, useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { AppContext } from "../../../../context/AppContext";
import { getCustomDomainStatusChipColor } from "../../../../utils/uiUtils.ts";
import StyledChip from "../../../../styles/components/Chip.Styled";
import CustomDomainContext from "./CustomDomainContext";
import RegistrarSelector, {
    pointingMethodeRegistrarAdapter,
    REGISTRAR_OPTIONS,
} from "./RegistrarSelector";

function ShowDNSInstructionsForPointingMethod() {
    const { domain } = useContext(CustomDomainContext);
    const [registrarSpecificIntruction, setRegistrarSpecificIntruction] =
        useState(domain);
    const [registrar, setRegistrar] = useState(REGISTRAR_OPTIONS.GENERAL);

    useEffect(() => {
        setRegistrarSpecificIntruction(
            pointingMethodeRegistrarAdapter(registrar, domain)
        );
    }, [registrar]);

    const { updateSnackbarStatus } = useContext(AppContext);

    const handleCopy = (value) => {
        navigator.clipboard.writeText(value);
        updateSnackbarStatus({
            message: `${value} copied to clipboard`,
        });
    };

    return (
        <Box>
            <Box className="ControllerItem">
                <h1>Which Registrar is your domain provider ?</h1>
                <RegistrarSelector
                    registrar={registrar}
                    setRegistrar={setRegistrar}
                />
            </Box>

            <Box className="DNSTableContainer">
                {registrarSpecificIntruction.dns_records.length > 0 && (
                    <>
                        <h1>
                            Add the following DNS records to your domain host
                            account
                        </h1>

                        <TableContainer component={Paper}>
                            <Table size="small" className="DNSTable DNSRecords">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Host</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {registrarSpecificIntruction.dns_records.map(
                                        (item) => (
                                            <TableRow key={item.type}>
                                                <TableCell scope="row">
                                                    {item.type}
                                                </TableCell>
                                                <TableCell>
                                                    {item.host}
                                                </TableCell>
                                                <TableCell>
                                                    <div>
                                                        {item.value}
                                                        <Button
                                                            onClick={() => {
                                                                handleCopy(
                                                                    item.value
                                                                );
                                                            }}
                                                            size="small"
                                                            variant="outline"
                                                            startIcon={
                                                                <ContentCopyIcon />
                                                            }
                                                        >
                                                            Copy
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                {registrarSpecificIntruction.dnsMessage && (
                    <>
                        <h1>{registrarSpecificIntruction.dnsMessage.title}</h1>
                        <TableContainer
                            component={Paper}
                            className="DNSTable DNSMessage"
                        >
                            <Table
                                size="small"
                                className="DNSTable ForwardingInstruction"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {
                                                registrarSpecificIntruction
                                                    .dnsMessage
                                                    .instructionsHeader
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {registrarSpecificIntruction.dnsMessage.instructions.map(
                                        (instruction) => (
                                            <TableRow>
                                                <TableCell>
                                                    {instruction}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                    {registrarSpecificIntruction.dnsMessage
                                        .link && (
                                        <TableRow>
                                            <TableCell>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    href={
                                                        registrarSpecificIntruction
                                                            .dnsMessage.link
                                                    }
                                                    target="_blank"
                                                    sx={{
                                                        textTransform: "none",
                                                    }}
                                                    rel="noopener noreferrer"
                                                >
                                                    Documentation Link ↗
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                {registrarSpecificIntruction.dns_records.length > 0 &&
                    !registrarSpecificIntruction.dnsMessage && (
                        <div>
                            <h2>
                                This domain is not attached to any application
                            </h2>
                        </div>
                    )}
            </Box>

            {registrarSpecificIntruction.certificate && (
                <Box className="DNSTableContainer">
                    <h1>SSL certification status</h1>
                    <p>
                        In order to make this domain available via https, please
                        add the following CNAME record and verify domain
                        ownership.
                    </p>

                    <TableContainer
                        component={Paper}
                        className="DNSTable CertificateTable"
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Domain</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>CNAME name</TableCell>
                                    <TableCell>CNAME value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell scope="row">
                                        {registrarSpecificIntruction.name}
                                    </TableCell>
                                    <TableCell scope="row">
                                        <StyledChip
                                            className="Capitalize"
                                            label={
                                                registrarSpecificIntruction
                                                    .certificate.status
                                            }
                                            color={getCustomDomainStatusChipColor(
                                                registrarSpecificIntruction
                                                    .certificate.status
                                            )}
                                            size="small"
                                        />
                                    </TableCell>

                                    <TableCell scope="row">CNAME</TableCell>
                                    <TableCell className="CellWithTextAndAction">
                                        <div>
                                            <div className="Text">
                                                {
                                                    registrarSpecificIntruction
                                                        .certificate.name
                                                }
                                            </div>

                                            <div className="Action">
                                                <Button
                                                    onClick={() => {
                                                        handleCopy(
                                                            registrarSpecificIntruction
                                                                .certificate
                                                                .name
                                                        );
                                                    }}
                                                    size="small"
                                                    variant="outline"
                                                    startIcon={
                                                        <ContentCopyIcon />
                                                    }
                                                >
                                                    Copy
                                                </Button>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell className="CellWithTextAndAction">
                                        <div>
                                            <div className="Text">
                                                {
                                                    registrarSpecificIntruction
                                                        .certificate.value
                                                }
                                            </div>
                                            <div className="Action">
                                                <Button
                                                    onClick={() => {
                                                        handleCopy(
                                                            registrarSpecificIntruction
                                                                .certificate
                                                                .value
                                                        );
                                                    }}
                                                    size="small"
                                                    variant="outline"
                                                    startIcon={
                                                        <ContentCopyIcon />
                                                    }
                                                >
                                                    Copy
                                                </Button>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box className="Notes">
                        <h3>Notes:</h3>
                        <Box>
                            <li>
                                Certificate processing time varies (up to 72
                                hours), depending on your SSL provider.
                            </li>
                        </Box>
                        <Box>
                            <li>
                                When the certificate is ready, the status will
                                be changed to{" "}
                                <span className="font-style-italic">
                                    ISSUED
                                </span>
                                .
                            </li>
                        </Box>
                        <Box>
                            <li>
                                If the CNAME record is not added within 72
                                hours, the status will be marked as{" "}
                                <span className="font-style-italic">
                                    VALIDATION_TIMED_OUT
                                </span>{" "}
                                status.
                            </li>
                        </Box>
                        <Box>
                            <li>
                                For more details, please consult the{" "}
                                <a
                                    href="https://docs.cloud.ploomber.io/en/latest/user-guide/custom-domains.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Documentation on Custom Domains
                                </a>
                                .
                            </li>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default ShowDNSInstructionsForPointingMethod;
