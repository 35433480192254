import { styled } from "@mui/material/styles";

const StyledApplicationLinksController = styled("div")(({ theme }) => ({
    "& > div": {
        display: "inline-flex",
    },
    "& .DefaultURLContainer": {
        margin: "auto",
    },
    "& .MuiSvgIcon-root": {
        fontSize: "1rem",
    },
}));

export default StyledApplicationLinksController;
