import { Box, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StyledCodeBloc from "../styles/components/StyledCodeBlock.Styled";

function CodeBloc({ children }) {
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(children);
        } catch (err) {
            // Empty
        }
    };
    return (
        <StyledCodeBloc className="bloc">
            <IconButton onClick={handleCopy} aria-label="copy code">
                <ContentCopyIcon fontSize="small" />
            </IconButton>
            <Box className="text">
                <code>{children}</code>
            </Box>
        </StyledCodeBloc>
    );
}

CodeBloc.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CodeBloc;
