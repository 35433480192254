import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ONBOARDING_PARAM = "onboarding";
const TEMPLATE_PARAM = "template";

function captureUrlParam(paramName, params, navigate, location) {
    const paramValue = params.get(paramName);
    if (paramValue) {
        localStorage.setItem(paramName, paramValue);
        params.delete(paramName);
        navigate(`${location.pathname}?${params.toString()}`, {
            replace: true,
        });
    }
}

export function captureOnboardingUrlParam() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        captureUrlParam(ONBOARDING_PARAM, params, navigate, location);
        captureUrlParam(TEMPLATE_PARAM, params, navigate, location);
    }, [location, navigate]);
}

export function getOnboardingFramework() {
    const onboardingFramework = localStorage.getItem(ONBOARDING_PARAM);
    if (onboardingFramework) {
        return onboardingFramework.toLocaleLowerCase();
    }
    return null;
}

export function clearOnboardingFramework() {
    localStorage.removeItem(ONBOARDING_PARAM);
}

export function getReferralTemplate() {
    const templateUrl = localStorage.getItem(TEMPLATE_PARAM);
    if (templateUrl) {
        return templateUrl;
    }
    return null;
}

export function clearReferralTemplate() {
    localStorage.removeItem(TEMPLATE_PARAM);
}
