import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledDeploymentCostRenderer = styled(Box)({
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",

    "& .Cost": {
        marginLeft: "0.3rem",
        marginRight: "0.1rem",
        fontWeight: "bold",
        fontSize: "0.8rem",
    },

    "& .InfoIcon": {
        fontSize: "2.5em",
    },
});

export default StyledDeploymentCostRenderer;
