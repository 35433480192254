import React from "react";
import StickyButton from "../../../../../components/UI/StickyButton";

export default function JoinSlackStickyButton() {
    return (
        <StickyButton
            onClick={() => {
                const url =
                    "https://ploomber-io.slack.com/join/shared_invite/zt-rzu2e9f6-kaWgCfsLY~xeJ9vG9EIuBw?utm_source=cloud_app";
                window.open(url, "_blank");
            }}
        >
            have an issue? join our slack
        </StickyButton>
    );
}
