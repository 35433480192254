import PropTypes from "prop-types";
import React, { useState, useContext, createContext } from "react";

const LoadingAndTourContext = createContext();

export function ProductTourProvider({ children }) {
    const [waitToStartTheTour, setWaitToStartTheTour] = useState(false);
    const [tourInProgress, setTourInProgress] = useState(false);

    return (
        <LoadingAndTourContext.Provider
            value={{
                waitToStartTheTour,
                setWaitToStartTheTour,
                tourInProgress,
                setTourInProgress,
            }}
        >
            {children}
        </LoadingAndTourContext.Provider>
    );
}

ProductTourProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
};

export function useProductTourContext() {
    const context = useContext(LoadingAndTourContext);
    if (!context) {
        throw new Error(
            "useLoadingAndTourContext must be used within a LoadingAndTourProvider"
        );
    }
    return context;
}
