import React from "react";
import PropTypes from "prop-types";
import { SelectOneGrid } from "../../../components/BlockTable";

function SelectProjectFrameworkGridController({
    selectedOption,
    onSelect,
    preSelectedId,
}) {
    return (
        <SelectOneGrid
            selectedOption={selectedOption}
            onOptionSelect={onSelect}
            preSelectedId={preSelectedId}
        />
    );
}

SelectProjectFrameworkGridController.propTypes = {
    selectedOption: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    preSelectedId: PropTypes.string,
};

SelectProjectFrameworkGridController.defaultProps = {
    preSelectedId: "",
};
export default SelectProjectFrameworkGridController;
