import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

const openedMixin = (theme) => ({
    width: theme.app.drawerWidth,
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    overflowX: "hidden",
    width: theme.app.closedDrawerWidth,
    backgroundColor: theme.palette.neutral.white,
});

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
    flexShrink: 0,
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
    "& a": {
        textDecoration: "none",
        color: theme.palette.neutral.black,
    },
    "&": {
        height: "100vh",
    },
    "& .TopMenu": {
        flexGrow: 1,
    },
    "& .BottomMenu": {
        flexGrow: 0,
        paddingTop: 0,
    },
    "& .MuiListItemButton-root": {
        height: 48,
        justifyContent: open ? "initial" : "center",
        padding: `0 ${theme.spacing(2.5)}`,
    },
    "& .MuiListItemIcon-root": {
        minWidth: 0,
        marginRight: open ? theme.spacing(2) : "auto",
        justifyContent: "center",
    },
    "& .MuiListItemText-root": {
        opacity: open ? 1 : 0,
    },
}));

export default StyledDrawer;
