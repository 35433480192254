import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { createFilterOptions } from "@mui/material/Autocomplete";
import StyledAutoCompleteInput from "../../../../styles/components/UI/AutoCompleteInput.Styled";

const filter = createFilterOptions();

export default function AutoCompleteInput({
    disabled,
    onChange,
    value,
    onBlur,
    error,
    label,
    options,
    id,
    placeholder,
    errorMessage,
}) {
    const handleChange = (e, newValue) => {
        const inputValue =
            typeof newValue === "string" ? newValue : e.target.value;
        if (onChange) {
            onChange(inputValue);
        }
    };

    return (
        <>
            {label && <div className="label">{label}</div>}

            <StyledAutoCompleteInput
                disabled={disabled}
                value={value}
                onInputChange={(event, newInputValue) => {
                    handleChange(event, newInputValue);
                }}
                filterOptions={(filterOptions, params) => {
                    const filtered = filter(filterOptions, params);
                    return filtered;
                }}
                id={id}
                options={options}
                getOptionLabel={(option) => {
                    if (typeof option === "string") {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => <li {...props}>{option}</li>}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        data-testid={id}
                        onBlur={onBlur}
                        onChange={handleChange}
                        error={error}
                        InputLabelProps={{ shrink: false }}
                        placeholder={placeholder}
                        {...params}
                        helperText={errorMessage}
                    />
                )}
            />
        </>
    );
}

AutoCompleteInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
};

AutoCompleteInput.defaultProps = {
    id: "AutoCompleteInput",
    placeholder: "Your text here",
    label: undefined,
    errorMessage: "",
};
