import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import { ListItemIcon } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import StyledDrawer from "../styles/layouts/Drawer.styled";
import StripePaymentButton from "../components/StripePaymentButton";
import { ReactComponent as PloomberSideBarLogo } from "../assets/ploomber-sidebar-logo.svg";
import { ReactComponent as PloomberSideBarLogoClosed } from "../assets/ploomber-sidebar-logo-closed.svg";
import { AppContext } from "../context/AppContext";
import { AccountContext } from "../features/user/Account";
import { UserType } from "../models/enum.ts";
import HelperTooltip from "../components/HelperTooltip";

function Sidebar({ links, bottomLinks, active, open }) {
    const { navigate } = useContext(AppContext);
    const { userType } = useContext(AccountContext);

    return (
        <StyledDrawer
            variant="permanent"
            anchor="left"
            open={open}
            className={open ? "open" : "closed"}
        >
            {/* TODO: Change this */}
            <Box
                sx={{
                    width: "50%",
                    marginLeft: 2,
                    marginTop: 3,
                }}
            >
                {open ? (
                    <div>
                        <PloomberSideBarLogo
                            className="cursor-pointer"
                            onClick={() => {
                                navigate("/");
                            }}
                            style={{
                                width: "70%",
                                height: "auto",
                            }}
                        />
                        {userType === UserType.ADMIN.value && (
                            <span data-testid="userinfo">
                                {" "}
                                {UserType.ADMIN.label}
                            </span>
                        )}
                        {userType === UserType.TEAMS.value && (
                            <span data-testid="userinfo">
                                {" "}
                                {UserType.TEAMS.label}
                            </span>
                        )}
                        {userType === UserType.PRO.value && (
                            <span data-testid="userinfo">
                                {" "}
                                {UserType.PRO.label}
                            </span>
                        )}
                        {userType === UserType.COMMUNITY.value && (
                            <span data-testid="userinfo">
                                {" "}
                                {UserType.COMMUNITY.label}
                            </span>
                        )}
                    </div>
                ) : (
                    <PloomberSideBarLogoClosed
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/");
                        }}
                        style={{
                            width: "100%",
                            height: "auto",
                        }}
                    />
                )}
            </Box>
            <Toolbar />
            <List className="TopMenu">
                {links.map((item) => {
                    const selected = active[0] === item.id;
                    const textToDisplay = `${item.id[0].toUpperCase()}${item.id.slice(
                        1
                    )}`;
                    return (
                        <HelperTooltip
                            key={item.id}
                            text={open ? "" : item.id}
                            placement="right-end"
                        >
                            <Link to={item.to}>
                                <ListItem
                                    id={item.id}
                                    disablePadding
                                    selected={selected}
                                    sx={{ display: "block" }}
                                >
                                    <ListItemButton>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={textToDisplay} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        </HelperTooltip>
                    );
                })}
            </List>
            <StripePaymentButton showText={open} />
            <List className="BottomMenu">
                {bottomLinks.map((item) => {
                    const selected = active[0] === item.id;
                    const textToDisplay = `${item.id[0].toUpperCase()}${item.id.slice(
                        1
                    )}`;
                    return (
                        <HelperTooltip
                            key={item.id}
                            text={open ? "" : item.id}
                            placement="right-end"
                        >
                            <ListItem
                                onClick={item.action}
                                disablePadding
                                selected={selected}
                                data-testid={`side-bar-button-${item.id}`}
                            >
                                <ListItemButton>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={textToDisplay} />
                                </ListItemButton>
                            </ListItem>
                        </HelperTooltip>
                    );
                })}
            </List>

            <div
                style={{
                    position: "fixed",
                    left: open ? 250 : 70,
                    bottom: 0,
                    backgroundColor: "#fff",
                    width: "100%",
                    padding: "17px 0",
                }}
            >
                Have an issue? Join our{" "}
                <a
                    href="https://ploomber-io.slack.com/join/shared_invite/zt-rzu2e9f6-kaWgCfsLY~xeJ9vG9EIuBw?utm_source=cloud_app"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline" }}
                >
                    Slack
                </a>
            </div>
        </StyledDrawer>
    );
}

Sidebar.propTypes = {
    bottomLinks: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape)])
        .isRequired,
    links: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape)]).isRequired,
    active: PropTypes.arrayOf(PropTypes.string),
    open: PropTypes.bool,
};

Sidebar.defaultProps = { active: [], open: true };

export default Sidebar;
