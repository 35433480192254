import React from "react";
import PropTypes from "prop-types";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";

function RefreshButton({ onClick, style }) {
    return (
        <button
            type="button"
            onClick={onClick}
            style={style}
            data-testid="fetch-data-button"
        >
            <SyncRoundedIcon />
        </button>
    );
}

RefreshButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};

RefreshButton.defaultProps = {
    style: undefined,
};

export default RefreshButton;
