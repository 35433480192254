import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

function Input({
    id,
    value,
    placeholder,
    disabled,
    onChange,
    onBlur,
    error,
    className,
}) {
    return (
        <TextField
            className={className}
            id={id}
            variant="outlined"
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            fullWidth
            onBlur={onBlur}
            error={error}
        />
    );
}

Input.defaultProps = {
    value: "",
    placeholder: "",
    disabled: false,
    id: "",
    onChange: () => {},
    onBlur: () => {},
    error: false,
    className: "",
};

Input.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    className: PropTypes.string,
};

export default Input;
