import React, { useContext } from "react";
import PropTypes from "prop-types";
import HelperTooltip from "../../../components/HelperTooltip";
import { AccountContext } from "../../user/Account";
import { handleStripeSubmit } from "../../../components/StripePaymentButton";
import { AppContext } from "../../../context/AppContext";

export function RestrictedController({ children, toolTipMsg }) {
    const { updateSnackbarStatus } = useContext(AppContext);
    const stripePaymentHandler = (e) => {
        const newTab = e.metaKey || e.ctrlKey || e.button === 1;
        e.preventDefault();
        handleStripeSubmit(updateSnackbarStatus, "pro", newTab);
    };
    return (
        <HelperTooltip
            text={
                <div
                    role="button"
                    onKeyDown={() => {}}
                    tabIndex="0"
                    onClick={stripePaymentHandler}
                >
                    <div>{toolTipMsg}</div>

                    <div>
                        <span
                            style={{
                                textDecoration: "underline",
                            }}
                        >
                            <a
                                href="/"
                                onClick={stripePaymentHandler}
                                style={{
                                    textDecoration: "inherit",
                                    color: "inherit",
                                }}
                            >
                                Click here
                            </a>
                        </span>{" "}
                        to upgrade your account.
                    </div>
                </div>
            }
        >
            {children ?? children}
        </HelperTooltip>
    );
}

RestrictedController.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]),
    toolTipMsg: PropTypes.string,
};

RestrictedController.defaultProps = {
    children: undefined,
    toolTipMsg: "This option is not available for your plan",
};

function UserValidation(Component) {
    function ComponentWithUserValidation(props) {
        const { getUserConfiguration } = useContext(AccountContext);
        const { hideTooltipIcon, featureId, disableUserCheck, toolTipMsg } =
            props;

        const userAllowedFeatures = getUserConfiguration("allowedFeatures");
        const canUseController = disableUserCheck
            ? true
            : userAllowedFeatures.includes(featureId);

        if (canUseController) {
            return <Component {...props} />;
        }

        const ComponentToRender = (
            <Component {...props} className="DisableComponent" />
        );

        return (
            <div
                style={{
                    display: "inline-flex",
                    flexDirection: "row-reverse",
                }}
                feature-id={featureId}
            >
                {hideTooltipIcon ? (
                    <RestrictedController>
                        <div>{ComponentToRender}</div>
                    </RestrictedController>
                ) : (
                    <>
                        <RestrictedController toolTipMsg={toolTipMsg} />
                        {ComponentToRender}
                    </>
                )}
            </div>
        );
    }

    ComponentWithUserValidation.propTypes = {
        featureId: PropTypes.string,
        hideTooltipIcon: PropTypes.bool,
        disableUserCheck: PropTypes.bool,
        toolTipMsg: PropTypes.string,
    };

    ComponentWithUserValidation.defaultProps = {
        featureId: undefined,
        hideTooltipIcon: false,
        disableUserCheck: false,
        toolTipMsg: undefined,
    };

    return ComponentWithUserValidation;
}

export default UserValidation;
