import React from "react";
import PropTypes from "prop-types";
import Select from "../../../components/UI/Form/inputs/Select";

function SelectProjectController({
    onChange,
    options,
    disabled,
    defaultValue,
}) {
    return (
        <div className="Controller" data-testid="projects-select-wrapper">
            <div className="label">Select application</div>

            <Select
                style={{ width: "100%" }}
                options={options}
                onChange={onChange}
                defaultValue={defaultValue}
                disabled={disabled}
            />
        </div>
    );
}

SelectProjectController.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    defaultValue: PropTypes.string.isRequired,
};

export default SelectProjectController;
