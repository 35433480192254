import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { parse } from "tldts";

export const REGISTRAR_OPTIONS = {
    GODADDY: "godaddy",
    GENERAL: "General",
};

/**
 * Extracts the root domain from a given domain string.
 *
 * @param {string} domain - The full domain name to extract the root domain from.
 * @returns {string} The root domain (e.g., "example.com" from "subdomain.example.com").
 *
 * @example
 * extractRootDomain("example.com") // returns "example.com"
 * extractRootDomain("www.example.co.uk") // returns "example.co.uk"
 */
export function extractRootDomain(domain) {
    try {
        const parsedDomain = parse(domain);
        return parsedDomain.domain;
    } catch (error) {
        return null;
    }
}

/**
 * Removes the root domain from the SSL CNAME record.
 * Fix for https://stackoverflow.com/a/63246970/709975
 *
 * @param {Object} adapted - The adapted domain object.
 * @param {string} adapted.name - The domain name.
 * @param {Object} adapted.certificate - The certificate object.
 * @param {string} adapted.certificate.name - The certificate name.
 */
export function removeRootDomainFromSslCname(adapted) {
    const rootDomain = extractRootDomain(adapted.name);
    if (rootDomain && adapted.certificate && adapted.certificate.name) {
        // eslint-disable-next-line no-param-reassign
        adapted.certificate.name = adapted.certificate.name.replace(
            `${rootDomain}.`,
            ""
        );
    }
}

/**
 * Adds GoDaddy-specific redirection instructions for top-level domains.
 * This function modifies the input object if certain conditions are met.
 *
 * @param {Object} adapted - The adapted domain object.
 * @param {string} adapted.name - The domain name.
 * @param {Array} adapted.dns_records - The DNS records for the domain.
 * @param {Object} [adapted.dnsMessage] - The DNS message object (will be created if needed).
 *
 * @returns {void} This function doesn't return a value, it modifies the input object.
 */
export function addGoDaddyRedirectionInstructionForTopDomain(adapted) {
    if (!adapted) {
        return;
    }
    const topDomain = extractRootDomain(adapted.name);
    if (topDomain === null) {
        return;
    }
    let hasAliasRoot = false;
    // GoDaddy: Redirection Instruction
    if (Array.isArray(adapted.dns_records)) {
        hasAliasRoot = adapted.dns_records.some(
            (record) => record.type === "ALIAS" && record.host === "@"
        );
    }
    if (hasAliasRoot) {
        // eslint-disable-next-line no-param-reassign
        adapted.dns_records = [];
        // eslint-disable-next-line no-param-reassign
        adapted.certificate = null;
        // eslint-disable-next-line no-param-reassign
        adapted.dnsMessage = {
            title: "Root Domain Forwarding",
            instructionsHeader: `Since GoDaddy doesn't support ALIAS records, you need to create a Permanent (301) forwarding rule to www.${topDomain}`,
            instructions: [
                `1. Set up a new subdomain for www.${topDomain}`,
                `2. Enter the CNAME record for the www subdomain and its SSL certificate.`,
                `3. In the forwarding tab of your domain on the GoDaddy website, create a Permanent (301) redirect rule pointing to www.${topDomain}`,
            ],
            link: "https://docs.cloud.ploomber.io/en/latest/user-guide/custom-domains.html#godaddy-redirect",
        };
    }
}

/**
 * Adapts the domain object based on the selected registrar for the pointing method.
 *
 * @param {string} registrar - The selected registrar (e.g., 'godaddy', 'namecheap', 'General').
 * @param {Object} domain - The original domain object.
 * @returns {Object} The adapted domain object.
 */
export function pointingMethodeRegistrarAdapter(registrar, domain) {
    const adapted = JSON.parse(JSON.stringify(domain));
    switch (registrar) {
        case REGISTRAR_OPTIONS.GODADDY:
            removeRootDomainFromSslCname(adapted);
            addGoDaddyRedirectionInstructionForTopDomain(adapted);
            break;
        default:
    }
    return adapted;
}

function RegistrarSelector({ registrar, setRegistrar }) {
    const handleRegistrarChange = (event, newRegistrar) => {
        setRegistrar(newRegistrar);
    };

    const inlineStyle = { textTransform: "none" };

    return (
        <ToggleButtonGroup
            value={registrar}
            exclusive
            onChange={handleRegistrarChange}
            aria-label="Registrar"
        >
            <ToggleButton
                sx={inlineStyle}
                value={REGISTRAR_OPTIONS.GENERAL}
                aria-label="Other"
            >
                General
            </ToggleButton>
            <ToggleButton
                sx={inlineStyle}
                value={REGISTRAR_OPTIONS.GODADDY}
                aria-label="GoDaddy"
                data-testid="registrar-select-godaddy"
            >
                GoDaddy
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

RegistrarSelector.propTypes = {
    registrar: PropTypes.oneOf(Object.values(REGISTRAR_OPTIONS)).isRequired,
    setRegistrar: PropTypes.func.isRequired,
};

export default RegistrarSelector;
