import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function CellWithSelect({
    options,
    keyToUpdate,
    rowIndex,
    value,
    onValueUpdate,
}) {
    const [inputValue, setInputValue] = useState(value);

    function handleChange(e) {
        const newValue = e.target.value;
        setInputValue(newValue);
        onValueUpdate(newValue, rowIndex, { keyToUpdate });
    }

    return (
        <div>
            <select
                className="Capitalize"
                onChange={handleChange}
                defaultValue={inputValue}
            >
                {options.map((option) => (
                    <option key={option}>{option}</option>
                ))}
            </select>
        </div>
    );
}

CellWithSelect.propTypes = {
    rowIndex: PropTypes.number.isRequired,
    keyToUpdate: PropTypes.string,
    value: PropTypes.string,
    onValueUpdate: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CellWithSelect.defaultProps = {
    value: "",
    keyToUpdate: undefined,
};

export default CellWithSelect;
