import React from "react";
import PropTypes from "prop-types";
import StyledSidebarMenu from "../../styles/components/SidebarMenu.Styled";

/**
 * This component renders a popup sidebar menu
 */
function SidebarMenu({ open, onClose, children }) {
    return (
        <StyledSidebarMenu
            anchor="right"
            open={open}
            onClose={onClose}
            id="SidebarMenu"
        >
            {children}
        </StyledSidebarMenu>
    );
}

SidebarMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
};

export default SidebarMenu;
