import React, { useRef, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ploomberAPI from "../../../../../services/ploomberAPI.ts";
import { handleStripeSubmit } from "../../../../../components/StripePaymentButton";
import { AppContext } from "../../../../../context/AppContext";
import { UserType } from "../../../../../models/enum.ts";
import { getUserSession } from "../../../../../features/user/Account";
import StyledActionButton from "../../../../../styles/components/UI/ActionButton.Styled";

const UNREGISTERED_USER = "unregistered";

function RedeployMessage({ message }) {
    return <p data-testid="relaunch-message">{message}</p>;
}

RedeployMessage.propTypes = {
    message: PropTypes.string.isRequired,
};

function SignUpLink() {
    return (
        <p data-testid="relaunch-register">
            <Link to="/register">Sign up to deploy your own apps.</Link>{" "}
        </p>
    );
}

function UpgradeToPro() {
    const { updateSnackbarStatus } = useContext(AppContext);
    const stripePaymentHandler = (e) => {
        e.preventDefault();
        handleStripeSubmit(updateSnackbarStatus, "pro", true);
    };
    return (
        <p className="info-text" data-testid="relaunch-update-to-pro">
            <StyledActionButton variant="link" onClick={stripePaymentHandler}>
                Upgrade to pro
            </StyledActionButton>{" "}
            to keep the app running 24/7.
        </p>
    );
}

const getUserType = () => {
    const user = getUserSession();
    if (user) {
        return user.type;
    }
    return UNREGISTERED_USER;
};

const checkIsOwner = async (projectId) => {
    try {
        const response = await ploomberAPI.getUserProject(projectId);
        return !!response;
    } catch (e) {
        return false;
    }
};

const matrix = {
    key_getter: getUserType,
    options: {
        [UserType.COMMUNITY.value]: {
            key_getter: checkIsOwner,
            options: {
                true: (
                    <>
                        <RedeployMessage message="Your app has been stopped." />
                        <br />
                        <UpgradeToPro />
                    </>
                ),
                false: (
                    <RedeployMessage message="This app has been stopped. Restart it by clicking the button above." />
                ),
            },
        },
        [UserType.PRO.value]: {
            key_getter: checkIsOwner,
            options: {
                true: (
                    <RedeployMessage message="Your app has been stopped. Click on the button above to restart." />
                ),
                false: (
                    <RedeployMessage message="This app has been stopped. Restart it by clicking the button above." />
                ),
            },
        },
        [UserType.TEAMS.value]: {
            key_getter: checkIsOwner,
            options: {
                true: (
                    <RedeployMessage message="Your app has been stopped. Click on the button above to restart." />
                ),
                false: (
                    <RedeployMessage message="This app has been stopped. Restart it by clicking the button above." />
                ),
            },
        },
        [UNREGISTERED_USER]: (
            <>
                <RedeployMessage message="This app has been stopped. Restart it by clicking the button above." />
                <br />
                <SignUpLink />
            </>
        ),
    },
};

const isReactComponent = (checkBlock) =>
    React.isValidElement(checkBlock) || typeof checkBlock === "function";

const getComponentToRenderOnRedeployScreen = async (checkBlock, projectId) => {
    if (isReactComponent(checkBlock)) {
        return checkBlock;
    }
    const keyGetterResult = await checkBlock.key_getter(projectId);
    return getComponentToRenderOnRedeployScreen(
        checkBlock.options[keyGetterResult],
        projectId
    );
};

function RelaunchPageMessage({ projectId }) {
    const [componentToRender, setComponentToRender] = useState(null);

    useEffect(() => {
        const fetchComponent = async () => {
            const component = await getComponentToRenderOnRedeployScreen(
                matrix,
                projectId
            );
            setComponentToRender(component);
        };
        fetchComponent();
    }, [projectId]);

    return (
        <>
            <p className="info-text">{componentToRender}</p>
            <br />
        </>
    );
}

RelaunchPageMessage.propTypes = {
    projectId: PropTypes.string.isRequired,
};

export default RelaunchPageMessage;
