import { Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSidebarMenu = styled(Drawer)(({ theme }) => ({
    "& > .MuiPaper-root": {
        padding: "0 30px",
        width: "90%",
        maxWidth: "90%",
        [theme.breakpoints.up("sm")]: {
            width: "70%",
        },
        [theme.breakpoints.up("md")]: {
            width: "50%",
        },
        [theme.breakpoints.up("lg")]: {
            width: "40%",
        },
    },
    "& *": {
        fontSize: "0.75rem",
    },
}));

export default StyledSidebarMenu;
