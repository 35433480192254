import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { ListItemIcon } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import ploomberAPI from "../services/ploomberAPI.ts";
import { AccountContext } from "../features/user/Account";
import { UserType } from "../models/enum.ts";
import { AppContext } from "../context/AppContext";
import telemetry from "../services/telemetry.ts";
import HelperTooltip from "./HelperTooltip";
import { PricingsDialog } from "./Pricings";
import {
    cancelSubscription,
    shouldValidateAction,
    capitalize,
} from "../utils/utils.ts";
import pricing from "./Pricings/Pricing.json";

export async function handleStripeSubmit(
    updateSnackbarStatus,
    upgradeType,
    newTab = false
) {
    let paymentUrl;
    try {
        paymentUrl = await ploomberAPI.createPaymentLink(upgradeType);
    } catch (error) {
        updateSnackbarStatus({
            message: `Error creating payment link: ${error}`,
            severity: "error",
        });
        return;
    }

    if (newTab) {
        window.open(paymentUrl.payment_url, "_blank").focus();
    } else {
        window.location.href = paymentUrl.payment_url;
    }
}

function StripePaymentButton({ showText }) {
    const { userType, setUserType, setSubscription, userHadFreeTrial } =
        useContext(AccountContext);
    const { navigate, updateSnackbarStatus } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [openPricingDialog, setOpenPricingDialog] = useState(false);
    const trial = localStorage.getItem("trial");

    const upgradeButtonText = userHadFreeTrial
        ? "Upgrade"
        : "Start your 10-day free trial";

    const pricingPlans = [
        {
            id: UserType.COMMUNITY.value,
            title: UserType.COMMUNITY.label,
            priceHTML: <div className="Price">Free</div>,
            features: pricing.sections.community,
            primaryAction: {
                title: shouldValidateAction(userType, UserType.COMMUNITY.value)
                    ? "Downgrade"
                    : "",
                onClick: async () => {
                    cancelSubscription(updateSnackbarStatus, (sub) => {
                        setOpenPricingDialog(false);
                        setSubscription(sub);
                    });
                },
            },
            preSelected: false,
        },
        {
            id: UserType.PRO.value,
            title: UserType.PRO.label,
            priceHTML: (
                <div>
                    <div className="Small">Starting at</div>

                    <div className="Price">$20</div>

                    <div className="Small">per month</div>
                </div>
            ),
            features: pricing.sections.professional,
            primaryAction: {
                title: shouldValidateAction(userType, UserType.PRO.value)
                    ? "Downgrade"
                    : upgradeButtonText,
                onClick: (id) => {
                    handleStripeSubmit(updateSnackbarStatus, id);
                },
            },
            preSelected: trial === UserType.PRO.value,
        },
        {
            id: UserType.TEAMS.value,
            title: UserType.TEAMS.label,
            priceHTML: (
                <div>
                    <div className="Small">Starting at</div>

                    <div className="Price">$200</div>

                    <div className="Small">per month</div>
                </div>
            ),
            features: pricing.sections.teams,
            primaryAction: {
                title: shouldValidateAction(userType, UserType.TEAMS.value)
                    ? "Downgrade"
                    : upgradeButtonText,
                onClick: (id) => {
                    handleStripeSubmit(updateSnackbarStatus, id);
                },
            },
            preSelected: trial === UserType.TEAMS.value,
        },
        {
            id: UserType.ENTERPRISE.value,
            title: UserType.ENTERPRISE.label,
            priceHTML: (
                <div className="Price CustomPricing">
                    <div>Custom pricing</div>
                </div>
            ),
            features: pricing.sections.enterprise,
            primaryAction: {
                title: "Let's talk",
                onClick: () => {
                    window.open(
                        "https://calendly.com/d/d2s-xd9-4r9/ploomber-founders",
                        "_blank"
                    );
                },
            },
            preSelected: trial === UserType.ENTERPRISE.value,
        },
    ];

    useEffect(() => {
        setIsLoading(true);

        if (trial) {
            setOpenPricingDialog(true);
        }

        let intervalId;
        const updateUserSession = async () => {
            try {
                const userSession = await ploomberAPI.getUserInfo();
                const returnedUserType = userSession?._model?.type;
                localStorage.setItem(
                    "ploomber_user_session",
                    JSON.stringify(userSession._model)
                );
                setUserType(returnedUserType);
                const url = new URL(window.location);

                // User pressed "cancel" during checkout
                if (!url.searchParams.has("verifyPromotion")) {
                    setIsLoading(false);
                    return;
                }

                // User completed checkout
                if (userType !== returnedUserType) {
                    // Payment verification passed
                    updateSnackbarStatus({
                        message: `Successful Update to ${capitalize(
                            returnedUserType
                        )} User`,
                    });
                    telemetry.log(`Payment Verified`, {
                        includeUserDetails: true,
                        metadata: { type: returnedUserType },
                    });
                } else {
                    // Payment verification failed
                    updateSnackbarStatus({
                        message: `Upgrade was unsuccessful. Your payment method was not charged.\nFor more details, please contact support: contact@ploomber.io`,
                        severity: "error",
                        duration: 20000, // Show snackbar for 20 seconds
                    });
                    telemetry.log(`Unable to verify payment`, {
                        includeUserDetails: true,
                    });
                }

                navigate("/applications");
                clearInterval(intervalId);
                setIsLoading(false);
            } catch (error) {
                updateSnackbarStatus({
                    message: `Error fetching user info: ${error}`,
                    severity: "error",
                });

                clearInterval(intervalId);
                setIsLoading(false);
            }
        };
        const searchParams = new URLSearchParams(window.location.search);
        const verifyPromotion = searchParams.get("verifyPromotion");

        if (verifyPromotion) {
            intervalId = setInterval(updateUserSession, 1000);
        } else {
            updateUserSession();
        }
    }, []); // Empty dependency array ensures this runs once

    if (isLoading) {
        return <div />;
    }

    return (
        <div>
            <HelperTooltip
                text={showText ? "" : "Upgrade"}
                placement="right-end"
            >
                <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: showText ? "initial" : "center",
                            px: 2.5,
                        }}
                        onClick={() => setOpenPricingDialog(true)}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: showText ? 2 : "auto",
                                justifyContent: "center",
                            }}
                        >
                            <UpgradeIcon />
                        </ListItemIcon>
                        <ListItemText
                            data-testid="upgradeButton"
                            primary="Upgrade"
                            sx={{ opacity: showText ? 1 : 0 }}
                        />
                    </ListItemButton>
                </ListItem>
            </HelperTooltip>

            <PricingsDialog
                pricingPlans={pricingPlans}
                open={openPricingDialog}
                onClose={() => {
                    // remove trial from localStorage so the stripe dialog is not shown
                    // again
                    localStorage.removeItem("trial");
                    setOpenPricingDialog(false);
                }}
            />
        </div>
    );
}

StripePaymentButton.propTypes = {
    showText: PropTypes.bool,
};

StripePaymentButton.defaultProps = { showText: true };

export default StripePaymentButton;
