import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import StyledLoader from "../styles/components/Loader.Styled";

function Loader({ inner, fullScreen }) {
    return (
        <StyledLoader>
            <div className="Container">
                <CircularProgress />
                <div>{inner}</div>
            </div>
        </StyledLoader>
    );
}

Loader.propTypes = {
    fullScreen: PropTypes.bool,
    inner: PropTypes.element.isRequired,
};

Loader.defaultProps = {
    fullScreen: true,
};

export default Loader;
