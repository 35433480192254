import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Link,
} from "@mui/material";
import PropTypes from "prop-types";

import "./SelectOneTable.css";

/**
 * Table that allows selecting one option, currently used for the examples
 * @param {string} selectedOption - The currently selected option
 * @param {function} onOptionSelect - Callback to execute when an option is selected
 * @returns {JSX.Element}
 */
const SelectOneTable = function ({
    selectedOption,
    onOptionSelect,
    selectedFramework,
    currentExamples,
}) {
    const handleOptionSelect = (option) => {
        onOptionSelect(option);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {currentExamples.map((option) => (
                        <TableRow
                            key={option.id}
                            onClick={() => handleOptionSelect(option)}
                            className={
                                selectedOption === option.id
                                    ? "selected-row"
                                    : "non-selected-row"
                            }
                            id={`example-${option.id}`}
                        >
                            <TableCell>
                                <strong>{option.title}</strong>
                            </TableCell>
                            <TableCell>{option.description}</TableCell>
                            <TableCell>
                                <span />
                            </TableCell>
                            <TableCell>
                                {selectedFramework === "voila" ? (
                                    <Link
                                        href={option.gitSourceLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        View source
                                    </Link>
                                ) : (
                                    <Link
                                        href={option.gitRawLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Download source
                                    </Link>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

SelectOneTable.propTypes = {
    selectedOption: PropTypes.string.isRequired,
    onOptionSelect: PropTypes.func.isRequired,
    selectedFramework: PropTypes.string.isRequired,
    currentExamples: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
        .isRequired,
};

export default SelectOneTable;
