import React, { useRef } from "react";
import "./css/style.css";
import PropTypes from "prop-types";
import { togglePasswordVisibility } from "../../../../utils/uiUtils.ts";

function VerifyPasswordRecoveryView(props) {
    const { onVerifyNewPassword } = props;

    const codeInputRef = useRef(null);
    const passwordInputRef = useRef(null);

    const handleVerifyNewPassword = (e) => {
        e.preventDefault();
        const code = codeInputRef.current.value;
        const password = passwordInputRef.current.value;

        onVerifyNewPassword(code, password);
    };

    return (
        <section className="main-content">
            <div className="login-card">
                <h1 className="form-header playfair-font">Forgot Password</h1>
                <form id="forgotVerifyForm" onSubmit={handleVerifyNewPassword}>
                    <div className="input-group">
                        <input
                            ref={codeInputRef}
                            type="password"
                            id="verifyForgot"
                            className="input-email"
                            placeholder="Verification code"
                            required
                            data-testid="verificationCode"
                        />
                    </div>
                    <div className="input-group">
                        <input
                            ref={passwordInputRef}
                            type="password"
                            id="passForgot"
                            className="input-email"
                            placeholder="New password"
                            pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            title="Password requirements not fulfilled."
                            required
                            data-testid="newPassword"
                        />
                        <i
                            className="far fa-eye"
                            id="togglePasswordForgot"
                            role="button"
                            onKeyPress={(e) => {}}
                            tabIndex="0"
                            onClick={(e) => {
                                togglePasswordVisibility(passwordInputRef);
                            }}
                            aria-label="Toggle password"
                        />

                        <p>
                            Minimum 8 characters, with at least one special
                            symbol, one uppercase and one lowercase letter{" "}
                        </p>
                    </div>
                    <input
                        type="submit"
                        className="btn-next"
                        value="Verify"
                        data-testid="submit"
                    />
                </form>
            </div>
        </section>
    );
}

VerifyPasswordRecoveryView.propTypes = {
    onVerifyNewPassword: PropTypes.func.isRequired,
    // onSuccess: PropTypes.func.isRequired,
    // onFailure: PropTypes.func.isRequired,
};

export default VerifyPasswordRecoveryView;
