import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledChip = styled(Chip)(({ theme }) => ({
    "&.MuiChip-colorInfo": {
        backgroundColor: "#E3D026",
        color: "#000",
    },
}));

export default StyledChip;
