import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const StyledStickyButton = styled(Button)(({ theme }) => ({
    color: "#000",
    i: {
        margin: "auto",
    },
}));

export default StyledStickyButton;
