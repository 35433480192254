import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import StyledStickyButton from "../../styles/components/UI/StickyButton.Styled";

function StickyButton({ onClick, children, endIcon, startIcon }) {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <StyledStickyButton
                onClick={onClick}
                variant="outlined"
                endIcon={endIcon}
                startIcon={startIcon}
            >
                {children}
            </StyledStickyButton>
        </Box>
    );
}

StickyButton.propTypes = {
    endIcon: PropTypes.element,
    startIcon: PropTypes.element,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.string,
    ]).isRequired,
    onClick: PropTypes.func.isRequired,
};

StickyButton.defaultProps = {
    endIcon: undefined,
    startIcon: undefined,
};

export default StickyButton;
