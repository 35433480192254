import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AccountContext } from "../../features/user/Account";
import { AppContext } from "../../context/AppContext";
import { getRedirectParam } from "../../utils/utils.ts";

function AuthorizedRoute({ children }) {
    const { getSession, authed } = useContext(AccountContext);
    const { navigate, searchParams } = useContext(AppContext);

    const [pAuthed, setPAuthed] = useState();

    useEffect(() => {
        let subscribed = true;

        if (!authed) {
            getSession(false)
                .then((session) => {
                    if (subscribed) {
                        if (session) {
                            setPAuthed(true);
                        } else {
                            setPAuthed(false);
                        }
                    }
                })
                .catch((err) => {
                    setPAuthed(false);
                });
        } else {
            setPAuthed(true);
        }

        return () => {
            subscribed = false;
        };
    }, []);

    if (pAuthed === undefined) {
        // TODO: Add a loader...
        return null;
    }

    if (!pAuthed) {
        const originalRoute = window.location.pathname;

        // INFO: `returnUrl` will redirect to a relative path
        let returnUrl = originalRoute;

        // INFO: `redirect` will send the user to another site
        const redirectParam = getRedirectParam(searchParams);
        if (searchParams) {
            // INFO: deleting the `redirect` from `returnUrl` for aesthetic reasons
            // - otherwise, the parameter is in the URL twice
            searchParams.delete("redirect");
            returnUrl += searchParams.toString();
        }

        const finalUrl = redirectParam
            ? `/signin?returnUrl=${encodeURIComponent(
                  returnUrl
              )}&${redirectParam}`
            : `/signin?returnUrl=${encodeURIComponent(returnUrl)}`;
        navigate(finalUrl);
        return "";
    }

    return children;
}

AuthorizedRoute.propTypes = {
    children: PropTypes.element.isRequired,
};

export default AuthorizedRoute;
