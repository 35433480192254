import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Box, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoIcon from "@mui/icons-material/Info";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AppContext } from "../../../context/AppContext";
import SidebarMenu from "../../../components/UI/SidebarMenu";
import ShowDNSInstructionsForPointingMethod from "../../applications/controllers/customDomainConroller/ShowDNSInstructionsForPointingMethod";
import CustomDomainContext from "../../applications/controllers/customDomainConroller/CustomDomainContext";
import StyledCustomDomainRecord from "../../../styles/features/dashboards/controllers/CustomDomainRecord.Styled";
import DeleteCustomDomainDialog from "../../../components/DeleteCustomDomainDialog";

export default function DomainsActionsRenderer(props) {
    const { updateSnackbarStatus } = useContext(AppContext);
    const [openDomainInfoDialog, setOpenDomainInfoDialog] = useState(false);
    const [openDeleteCustomDialog, setOpenDeleteCustomDialog] = useState(false);

    const { data, onDomainDeleted, isLoadingSpinner, customDomains } = props;
    const domain = data;
    const id = domain.name.replace(".", "");

    const handleCopy = () => {
        const textToCopy = domain.name;
        navigator.clipboard.writeText(textToCopy);
        const message = `${textToCopy} copied to clipboard`;
        updateSnackbarStatus({
            message,
        });
    };

    const disabledStyle = {
        opacity: 0.5,
        pointerEvents: "none",
    };

    const handleCloseSidebarMenu = () => {
        setOpenDomainInfoDialog(false);
    };

    const handleOpenSidebarMenu = () => {
        setOpenDomainInfoDialog(true);
    };

    const handleDomainDeleted = () => {
        onDomainDeleted();
        handleCloseSidebarMenu();
    };

    return (
        <div
            style={isLoadingSpinner ? disabledStyle : {}}
            data-testid={`${domain.name}-action-button`}
        >
            <IconButton
                size="small"
                onClick={handleCopy}
                data-testid={`${domain.name}-copy-button`}
            >
                <ContentCopyIcon fontSize="small" color="primary" />
            </IconButton>

            <IconButton
                size="small"
                onClick={handleOpenSidebarMenu}
                data-testid={`${domain.name}-sidebar-button`}
            >
                <InfoIcon fontSize="small" color="primary" />
            </IconButton>

            <IconButton
                size="small"
                onClick={() => {
                    setOpenDeleteCustomDialog(true);
                }}
                data-testid={`${domain.name}-delete-button`}
            >
                <DeleteForeverIcon fontSize="small" color="primary" />
            </IconButton>

            <SidebarMenu
                open={openDomainInfoDialog}
                onClose={handleCloseSidebarMenu}
            >
                <CustomDomainContext.Provider value={{ domain }}>
                    <StyledCustomDomainRecord
                        id={`CustomDomainRecord-${id}`}
                        data-testid={`CustomDomainRecord-${id}`}
                        className="CustomDomainRecord ControllerItem"
                    >
                        <Box className="ExpansionContainer">
                            <Box className="DNSInstructions">
                                <Box className="NSDetailsContainer">
                                    <ShowDNSInstructionsForPointingMethod
                                        domain={domain}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </StyledCustomDomainRecord>
                </CustomDomainContext.Provider>
            </SidebarMenu>

            <DeleteCustomDomainDialog
                open={openDeleteCustomDialog}
                onDelete={handleDomainDeleted}
                onClose={() => {
                    setOpenDeleteCustomDialog(false);
                }}
                domainName={domain.name}
                domains={customDomains}
            />
        </div>
    );
}

DomainsActionsRenderer.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    onDomainDeleted: PropTypes.func.isRequired,
    isLoadingSpinner: PropTypes.bool.isRequired,
    customDomains: PropTypes.arrayOf(PropTypes.string).isRequired,
};
