import React, { useEffect, useState, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Card, CircularProgress, LinearProgress } from "@mui/material";

import HelperTooltip from "../../../components/HelperTooltip";
import { AppContext } from "../../../context/AppContext";
import ploomberAPI from "../../../services/ploomberAPI.ts";
import SettingsGridLayout from "./SettingsGridLayout";
import { AccountContext } from "../../user/Account";
import { UserType } from "../../../models/enum.ts";
import { formatDate, getBillingDue } from "../../../utils/utils.ts";

import StyledCreditUsage from "../../../styles/components/Billing/CreditUsage.Styled";

function BillingGrid() {
    const { user, updateSnackbarStatus, creditInfo } = useContext(AppContext);
    const { userType } = useContext(AccountContext);

    const [billingHistory, setBillingHistory] = useState([]);
    const [historyLoading, setHistoryLoading] = useState(true);
    const [shouldPaginate, setShouldPaginate] = useState(false);

    const creditsStartPeriod =
        userType === UserType.COMMUNITY.value
            ? "on the first of each month"
            : "at the beginning of each billing cycle";
    const creditUsageHelpText = `Users receive 2,232 credits ${creditsStartPeriod}, which equates to 72 credits per day. Unused credits from the previous cycle expire and are replaced by the new allocation.`;

    const exportHelpText =
        userType === UserType.COMMUNITY.value
            ? "Export usage per app for the current month"
            : "Export usage per app for the current billing period";

    useEffect(() => {
        const fetchBillingHistory = async () => {
            try {
                setHistoryLoading(true);
                const response = await ploomberAPI.getUserBillingHistory(
                    user.id
                );
                setBillingHistory(response);
                setShouldPaginate(
                    Array.isArray(response) && response.length > 5
                );
            } catch (error) {
                updateSnackbarStatus({
                    message: "Failed to fetch user billing history",
                    severity: "error",
                });
            } finally {
                setHistoryLoading(false);
            }
        };
        fetchBillingHistory();
    }, [user]);

    const convertToCSV = (objArray) => {
        const headers = ["Project", "CPU Usage", "RAM Usage", "GPU Usage"];
        const csvRows = [];
        csvRows.push(headers.join(","));

        Object.entries(objArray).forEach(([appName, specs]) => {
            const cpu = specs.cpu_usage;
            const ram = specs.ram_usage;
            const gpu = specs.gpu_usage;
            const csvRow = `${appName},${cpu},${ram},${gpu}`;
            csvRows.push(csvRow);
        });
        return csvRows.join("\n");
    };

    const handleBillingInfoExport = () => {
        const csv = convertToCSV(creditInfo.usage_by_app);
        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        const exportDate = new Date().toISOString().split("T")[0];
        a.download = `ploomber_billing_information_${exportDate}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const billingHistoryColumnDefs = [
        {
            headerName: "Invoice",
            field: "invoice_id",
            sortable: false,
            flex: 2,
        },
        {
            headerName: "Date",
            width: 130,
            field: "invoice_date",
            valueFormatter: (params) => {
                const date = new Date(params.value * 1000);
                return date.toISOString().slice(0, 10);
            },
            filter: "agDateColumnFilter",
        },
        {
            headerName: "Amount",
            field: "invoice_amount",
            type: "numericColumn",
            valueFormatter: (params) => `$${params.value.toFixed(2)}`,
        },
        { headerName: "Status", field: "status" },
    ];

    return (
        <SettingsGridLayout
            id="billingGrid"
            loading={historyLoading}
            title="Billing"
            actions={
                <HelperTooltip text={exportHelpText} placement="top">
                    <Button
                        data-testid="exportUsageButton"
                        variant="contained"
                        color="primary"
                        onClick={handleBillingInfoExport}
                        disabled={
                            Object.keys(creditInfo.usage_by_app).length === 0
                        }
                    >
                        Export
                    </Button>
                </HelperTooltip>
            }
        >
            <h4>Credit usage</h4>

            {creditInfo.loading && <CircularProgress size={20} />}

            {!creditInfo.loading && creditInfo.allotted_credits !== 0 && (
                <StyledCreditUsage item xs={12}>
                    <div className="BillingGrid">
                        <div className="BillingDue" data-testid="billingDue">
                            Next billing due:{" "}
                            {getBillingDue(creditInfo.billing_end)}
                        </div>
                    </div>

                    <Card className="CreditsCard">
                        <div className="CreditsBarContainer">
                            <div className="CreditsBar">
                                Credits applied
                                <HelperTooltip
                                    text={creditUsageHelpText}
                                    placement="right-start"
                                />
                            </div>
                            <div data-testid="creditUsage">
                                {creditInfo.used_credits}/
                                {creditInfo.allotted_credits}
                            </div>
                        </div>

                        <LinearProgress
                            variant="determinate"
                            value={
                                (creditInfo.used_credits /
                                    creditInfo.allotted_credits) *
                                100
                            }
                        />
                    </Card>
                </StyledCreditUsage>
            )}

            {!creditInfo.loading && creditInfo.allotted_credits === 0 && (
                <div style={{ paddingBottom: "1rem" }}>
                    No data available. Join our{" "}
                    <a
                        href="https://ploomber-io.slack.com/join/shared_invite/zt-rzu2e9f6-kaWgCfsLY~xeJ9vG9EIuBw?utm_source=cloud_app"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            textDecoration: "underline",
                            marginRight: "0.3rem",
                        }}
                    >
                        Slack
                    </a>
                    for support.
                </div>
            )}

            <h4>Billing history</h4>

            <AgGridReact
                autoSizePadding={0}
                domLayout="autoHeight"
                columnDefs={billingHistoryColumnDefs}
                rowData={Array.isArray(billingHistory) ? billingHistory : []}
                defaultColDef={{
                    sortable: true,
                    filter: true,
                    resizable: true,
                    flex: 1,
                }}
                enableCellTextSelection
                pagination={shouldPaginate}
                paginationPageSize={5}
                suppressPaginationPanel={!shouldPaginate}
            />
        </SettingsGridLayout>
    );
}

export default BillingGrid;
