import React, { useContext } from "react";
import PropTypes from "prop-types";
import BlockContext from "../../context/BlockContext";

function BlockContent({ children, style }) {
    const { openBlock } = useContext(BlockContext);

    if (openBlock) {
        return (
            <div className="Content" style={style}>
                {children}
            </div>
        );
    }
    return "";
}

BlockContent.defaultProps = {
    style: {},
};

BlockContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        ),
        PropTypes.element,
    ]).isRequired,

    style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};

export default BlockContent;
