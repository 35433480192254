import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UserType } from "../../../models/enum.ts";
import Select from "../../../components/UI/Form/inputs/Select";
import telemetry from "../../../services/telemetry.ts";
import { AccountContext } from "../../user/Account";
import { ProjectContext } from "../../../context/ProjectContext";
import ControllerLabel from "../../../components/UI/ControllerLabel";
import ploomberAPI from "../../../services/ploomberAPI.ts";
import { hasApps } from "../../../utils/utils.ts";

function SelectController({
    optionsData,
    onChange,
    featureId,
    value,
    labelText,
    unitText,
    shouldDisplayPricePerHour,
}) {
    const { userType, resourcesInfo } = useContext(AccountContext); // this resource info is for all the resources consumed by this account
    const { resourcesInfo: curJobResourceInfo } = useContext(ProjectContext); // this resource info is only for the selected project
    const isAppsActive = hasApps(resourcesInfo, curJobResourceInfo);

    const [options, setOptions] = useState([
        { text: "0", value: "0", disabled: true },
    ]);
    const [selectedValue, setSelectedValue] = useState("");
    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        onChange(newValue);
    };
    const handleDisabledClick = async (option) => {
        // Handle the click event for disabled items
        if (option.disabled) {
            const paymentUrl = await ploomberAPI.createPaymentLink(
                UserType.PRO.value
            );
            window.open(paymentUrl.payment_url, "_blank");
        }
        return null;
    };

    useEffect(() => {
        if (optionsData && optionsData.length > 0) {
            const allowedOptions = optionsData.map(
                ([optionValue, isEnabled, pricePerHour]) => {
                    const finalOptionValue =
                        optionValue === "0" ? "No" : optionValue;
                    const baseText = `${finalOptionValue} ${unitText}`;
                    let displayText = baseText;
                    if (
                        (userType === UserType.PRO.value ||
                            userType === UserType.TEAMS.value) &&
                        pricePerHour &&
                        shouldDisplayPricePerHour(isAppsActive, optionValue)
                    ) {
                        // Show "Per second billing" for the options with pricing involved for Pro users
                        displayText += ` ($${pricePerHour} per hour)`;
                    } else if (!isEnabled) {
                        displayText += " (Upgrade To Pro)";
                    }

                    return {
                        text: displayText,
                        disabled: !isEnabled,
                        value: optionValue,
                    };
                }
            );
            // Set the initial selected value
            if (!selectedValue) {
                setSelectedValue(allowedOptions[0].value);
            }
            setOptions(allowedOptions);
        }
    }, [optionsData]);

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    return (
        <div feature-id={featureId}>
            <ControllerLabel text={labelText} featureId={featureId} />

            <Select
                style={{ width: "100%" }}
                options={options}
                defaultValue={options[0].value || ""}
                selectedValue={selectedValue}
                onChange={handleSelectChange}
                onOptionClick={handleDisabledClick}
                onOpen={() => {
                    telemetry.log(
                        `${telemetry.Pages.CreateApplication}-CustomHardware-${labelText}`
                    );
                }}
            />
        </div>
    );
}

SelectController.propTypes = {
    curJobResourceContext: PropTypes.shape({
        currentCpu: PropTypes.string.isRequired,
        currentRam: PropTypes.string.isRequired,
        currentGpu: PropTypes.string.isRequired,
    }), // ensure it matches the "ResourcesInfo" interface. without typescript, this is the only way
    optionsData: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
        )
    ),
    onChange: PropTypes.func.isRequired,
    featureId: PropTypes.string.isRequired,
    value: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    unitText: PropTypes.string.isRequired,
    shouldDisplayPricePerHour: PropTypes.func,
};

SelectController.defaultProps = {
    curJobResourceContext: undefined,
    optionsData: [["2", false]], // default options
    shouldDisplayPricePerHour: () => true,
    value: undefined,
};

export default SelectController;
