import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";
import ControllerLabel from "../../../../components/UI/ControllerLabel";
import { ActionButton, RefreshButton } from "../../../../components/UI/index";
import { AppContext } from "../../../../context/AppContext";
import ploomberAPI from "../../../../services/ploomberAPI.ts";
import HelperTooltip from "../../../../components/HelperTooltip";
import { parseErrorMessage } from "../../../../utils/utils.ts";
import StyledController from "../../../../styles/features/dashboards/controllers/Controller.Styled";
import { AccountContext } from "../../../user/Account";
import CustomDomainRecord from "./CustomDomainRecord";
import { ApplicationSettingsContext } from "../../ApplicationSettingsContext";
import CustomDomainInput from "./CustomDomainInput";
import { RestrictedController } from "../../hoc/userValidation";

/**
 * Executes a function with retry capability
 *
 * @param {Function} func - The async function to execute
 * @param {Object} options - Retry configuration options
 * @param {number} [options.maxRetries=3] - Maximum number of retry attempts
 * @param {number} [options.delay=20000] - Delay between retries in milliseconds
 *
 * @returns {Promise} - Result of the function execution
 * @throws {Error} If there's an error after all retries.
 */
const withRetry = async (func, { maxRetries = 3, delay = 20000 } = {}) => {
    let numberOfTry = 0;

    const executeWithRetry = async () => {
        try {
            return await func();
        } catch (error) {
            if (numberOfTry < maxRetries) {
                numberOfTry += 1;

                // Wait for the specified delay before retrying
                await new Promise((resolve) => {
                    setTimeout(resolve, delay);
                });

                // Recursive retry
                return executeWithRetry();
            }

            // If max retries reached or shouldRetry returns false, throw the error
            throw error;
        }
    };

    return executeWithRetry();
};

const FEATURE_ID = "customDomain";

function SetCustomDomainController({ disabled }) {
    const { projectId } = useContext(ApplicationSettingsContext);

    const { canUserAccessComponent } = useContext(AccountContext);
    const { updateSnackbarStatus } = useContext(AppContext);

    const [isConnecting, setIsConnecting] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const [userRegisteredDomains, setUserRegisteredDomains] = useState([]);
    const [registeredDomains, setRegisteredDomains] = useState([]);
    const [domainToRegister, setDomainToRegister] = useState("");

    const [disableEntireComponent, setDisableEntireComponent] = useState(false);
    const [canConnect, setCanConnect] = useState(false);
    const [isCreatingDomains, setIsCreatingDomains] = useState(false);

    const [fetchingExistingDomains, setFetchingExistingDomains] =
        useState(true);

    /**
     * Waits for new custom domains to be created and fetches them.
     * Updates the registeredDomains state with the fetched domains.
     * Retries up to 3 times with a 20-second delay between attempts.
     *
     * @async
     */
    const waitAndFetchNewDomains = async () => {
        try {
            setIsCreatingDomains(true);
            const domains = await withRetry(
                () => ploomberAPI.getCustomDomains(projectId),
                {
                    maxRetries: 3,
                    delay: 20000,
                }
            );
            setRegisteredDomains(domains);
        } catch (err) {
            if (err.title || err.detail) {
                updateSnackbarStatus({
                    severity: "error",
                    message: parseErrorMessage(err),
                });
            } else {
                updateSnackbarStatus({
                    severity: "warning",
                    message:
                        "The infrastructure is taking longer than expected. Please try again in a few minutes. If the problem persists, please contact us on Slack for assistance.",
                });
            }
        } finally {
            setIsCreatingDomains(false);
        }
    };

    /**
     * Fetches custom domains for the current project.
     * Updates the registeredDomains state with the fetched domains.
     * Displays an error message if the fetch fails.
     *
     * @async
     * @function getCustomDomains
     * @returns {Promise<void>} A promise that resolves when the operation is complete.
     */
    const getProjectCustomDomains = async () => {
        setIsRefreshing(true);
        await new Promise((resolve) => {
            ploomberAPI
                .getCustomDomains(projectId)
                .then((domains) => {
                    setRegisteredDomains(domains);
                })
                .catch((err) => {
                    updateSnackbarStatus({
                        severity: "error",
                        message: parseErrorMessage(err),
                    });
                })
                .finally(() => {
                    resolve();
                    setIsRefreshing(false);
                });
        });
    };

    const getUserRegisteredDomains = async () => {
        try {
            setFetchingExistingDomains(true);
            const domains = await ploomberAPI.getCustomDomains();
            setUserRegisteredDomains(domains);
        } catch (err) {
            updateSnackbarStatus({
                severity: "error",
                message: parseErrorMessage(err),
            });
        } finally {
            setFetchingExistingDomains(false);
        }
    };

    async function initialize() {
        getUserRegisteredDomains();

        const canUse = canUserAccessComponent(FEATURE_ID) && projectId;

        if (canUse) {
            setDisableEntireComponent(disabled);
            setIsRefreshing(true);
            await getProjectCustomDomains();
            setIsRefreshing(false);
        } else {
            setDisableEntireComponent(true);
        }
    }

    useEffect(() => {
        initialize();
    }, [disabled]);

    function handleConnectCustomDomainClick() {
        setIsConnecting(true);

        ploomberAPI
            .registerNewCustomDomain(domainToRegister, projectId)
            .then(async () => {
                await waitAndFetchNewDomains();

                setDomainToRegister("");
            })
            .catch((err) => {
                updateSnackbarStatus({
                    severity: "error",
                    message: parseErrorMessage(err),
                });
            })
            .finally(() => {
                setIsConnecting(false);
            });
    }

    const handleChangeDomainInput = (newValue) => {
        setDomainToRegister(newValue);
        setCanConnect(true);
    };

    const handleCustomDomainInputError = (e) => {
        setCanConnect(false);
    };

    useEffect(() => {
        if (isConnecting) {
            setCanConnect(false);
        }
    }, [isConnecting]);

    return (
        <StyledController className="Controller" id="setCustomDomainController">
            <ControllerLabel
                text="Connect a custom domain or subdomain"
                featureId={FEATURE_ID}
            />

            <Box className="ControllerItem">
                <Box className="InputWithButtonContainer">
                    <Box className="InputContainer">
                        <CustomDomainInput
                            disabled={
                                isConnecting ||
                                disableEntireComponent ||
                                fetchingExistingDomains
                            }
                            domains={userRegisteredDomains}
                            onChange={handleChangeDomainInput}
                            value={domainToRegister}
                            onValidationError={handleCustomDomainInputError}
                        />
                    </Box>

                    <ActionButton
                        id="connectDomainButton"
                        onClick={() => {
                            handleConnectCustomDomainClick();
                        }}
                        variant="contained"
                        disabled={!canConnect || domainToRegister.length === 0}
                        disabledOptions={
                            isConnecting
                                ? {
                                      text: "Connecting",
                                  }
                                : {}
                        }
                    >
                        Connect
                    </ActionButton>
                </Box>
                {isCreatingDomains && (
                    <p>
                        <CircularProgress size={8} /> Creating the necessary
                        infrastructure to host your domain, this can take up to
                        a minute.
                    </p>
                )}
            </Box>

            <Box className="ControllerItem">
                <Box className="RegisteredDomainsContainer">
                    <h2>
                        Registered domains or subdomains (
                        {registeredDomains?.length}){" "}
                    </h2>
                    <Box className="RefreshButtonContainer">
                        <HelperTooltip text="Refresh domains">
                            <RefreshButton
                                onClick={async () => {
                                    setIsRefreshing(true);
                                    await getProjectCustomDomains();
                                    setIsRefreshing(false);
                                }}
                            />
                        </HelperTooltip>
                    </Box>
                </Box>

                {isRefreshing ? (
                    <Box className="RefreshingContainer">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box>
                            {registeredDomains.map((domain) => (
                                <CustomDomainRecord
                                    id={domain.name.replaceAll(".", "")}
                                    domain={domain}
                                    onDomainDelete={getProjectCustomDomains}
                                    projectId={projectId}
                                    domains={userRegisteredDomains.map(
                                        (d) => d.name
                                    )}
                                />
                            ))}
                        </Box>

                        <Box>
                            {registeredDomains.length === 0 &&
                                "No registered domains or subdomains found"}
                        </Box>
                    </>
                )}
            </Box>
        </StyledController>
    );
}

SetCustomDomainController.propTypes = {
    disabled: PropTypes.bool,
};

SetCustomDomainController.defaultProps = {
    disabled: false,
};

export default SetCustomDomainController;
