import PropTypes from "prop-types";
import React, {
    useState,
    useCallback,
    useRef,
    useEffect,
    useMemo,
} from "react";
import JSZip from "jszip";
import UserInstruction from "./UserInstruction";
import FileDropzone from "./FileDropzone";
import { BlockTable } from "./BlockTable";
import ploomberAPI from "../services/ploomberAPI.ts";

const InstructionType = {
    SUCCESS: "success",
    INFO: "info",
    ERROR: "error",
    WARNING: "warning",
    EMPTY: "empty",
    LOADING: "loading",
};

const UploadInstruction = {
    // file type valiations
    PROMPT_ZIP: "Please provide a single zip file.",
    PROMPT_IPYNB_AND_REQUIREMENTS:
        "Please provide both an ipynb and a requirements.txt file.",
    PROMPT_EITHER_ZIP_OR_IPYNB_AND_REQUIREMENTS:
        "Please provide either a single zip file or both an ipynb and a requirements.txt file.",
    VALID_ZIP:
        "Valid file combination. One single zip file has been selected and content validation passed.",
    VALID_IPYNB_AND_REQUIREMENTS:
        "Valid file combination. Both an ipynb and a requirements.txt file have been selected.",
    INVALID_ZIP: "Invalid file combination. Please provide a single zip file.",
    INVALID_IPYNB_AND_REQUIREMENTS:
        "Invalid file combination. Please provide both an ipynb and a requirements.txt file.",
    INVALID_ZIP_OR_IPYNB_AND_REQUIREMENTS:
        "Invalid file combination. Please provide either a single zip file or both an ipynb and a requirements.txt file.",
    UNEXPECTED_ERROR:
        "Unexpected error. Please contact us on Slack for support. The link is at the bottom of the page.",
    // zipfile content validation
    PROMPT_ZIPFILE_ANALYSIS: "Analyzing the zip file...",
};

const FileTypeMIME = {
    ZIP: { "application/zip": [".zip"] },
    IPYNB: { "application/x-ipynb+json": [".ipynb"] },
    TXT: { "text/plain": [".txt"] },
};

export const Framework = {
    VOILA: "voila",
    DOCKER: "docker",
    STREAMLIT: "streamlit",
    PANEL: "panel",
    SOLARA: "solara",
    SHINY_R: "shiny-r",
    DASH: "dash",
    FLASK: "flask",
    CHAINLIT: "chainlit",
};

const zippedBasedFramework = [
    Framework.VOILA,
    Framework.DOCKER,
    Framework.PANEL,
    Framework.STREAMLIT,
    Framework.SOLARA,
    Framework.SHINY_R,
    Framework.DASH,
    Framework.FLASK,
    Framework.CHAINLIT,
];
const ipynbAndRequirementsBasedFramework = [Framework.VOILA];

// Function to template error messages
const templateErrorMessage = (missingFiles, missingExtensions) => {
    let message = `The uploaded zip file is missing `;
    if (missingFiles && missingFiles.length > 0) {
        message += `the following file(s): ${missingFiles.join(", ")}`;
    }
    if (missingExtensions && missingExtensions.length > 0) {
        if (missingFiles.length > 0) {
            message += ` and `;
        }
        message += `the following extension(s): ${missingExtensions.join(
            ", "
        )}`;
    }
    message += ".";
    return message;
};

/**
 * Component for handling file uploads with events,
 * Validates file combinations and displays messages
 * @param {React.Ref} fileError - Reference to the error message element.
 * @param {function} handleNotebookFileInputChange - Handler for notebook file input changes.
 * @param {function} handleRequirementsFileInputChange - Handler for requirements file input changes.
 * @param {function} handleZippedFileInputChange - Handler for zip file input changes.
 * @param {File[]} templateFiles - Array of template files to be automatically set
 * @returns {JSX.Element} The rendered UploadNewApplicationFiles component.
 */
const UploadNewApplicationFiles = function ({
    fileError,
    framework,
    handleNotebookFileInputChange,
    handleRequirementsFileInputChange,
    handleZippedFileInputChange,
    templateFiles,
}) {
    const frameworkRef = useRef(framework);

    const { isZippedBased, isIPYNBAndRequirementsBased } = useMemo(
        () => ({
            isZippedBased: zippedBasedFramework.includes(framework),
            isIPYNBAndRequirementsBased:
                ipynbAndRequirementsBasedFramework.includes(framework),
        }),
        [framework]
    );

    const { initialInstruction, acceptedMIME } = useMemo(() => {
        let initialInstructionInternal;
        let acceptedMIMEInternal;

        if (isZippedBased && isIPYNBAndRequirementsBased) {
            initialInstructionInternal =
                UploadInstruction.PROMPT_EITHER_ZIP_OR_IPYNB_AND_REQUIREMENTS;
            acceptedMIMEInternal = {
                ...FileTypeMIME.ZIP,
                ...FileTypeMIME.IPYNB,
                ...FileTypeMIME.TXT,
            };
        } else if (isZippedBased) {
            initialInstructionInternal = UploadInstruction.PROMPT_ZIP;
            acceptedMIMEInternal = { ...FileTypeMIME.ZIP };
        } else {
            initialInstructionInternal =
                UploadInstruction.PROMPT_IPYNB_AND_REQUIREMENTS;
            acceptedMIMEInternal = {
                ...FileTypeMIME.IPYNB,
                ...FileTypeMIME.TXT,
            };
        }

        return {
            initialInstruction: initialInstructionInternal,
            acceptedMIME: acceptedMIMEInternal,
        };
    }, [isZippedBased, isIPYNBAndRequirementsBased, framework]);

    const [instruction, setInstruction] = useState(initialInstruction);
    const [instructionType, setInstructionType] = useState(
        InstructionType.INFO
    );

    // Instruction for zip file analysis
    const [instructionZipfile, setInstructionZipfile] = useState([]);
    const [instructionTypeZipfile, setInstructionTypeZipfile] = useState([]);

    // previously dropped files
    const [droppedFiles, setDroppedFiles] = useState([]);
    const droppedFilesRef = useRef([]);
    droppedFilesRef.current = droppedFiles;

    const reset = () => {
        setDroppedFiles([]);
        setInstruction(initialInstruction);
        setInstructionType(InstructionType.INFO);

        setInstructionTypeZipfile(InstructionType.EMPTY);

        handleNotebookFileInputChange();
        handleRequirementsFileInputChange();
        handleZippedFileInputChange();
    };

    // Listen for changes in the 'framework' prop
    useEffect(() => {
        // Reset the state when 'framework' changes
        reset();
        frameworkRef.current = framework;
    }, [framework]);

    /**
     * Checks the content of a ZIP file against expected filenames and extensions for a specified framework
     * @param {Blob} zipFile - The ZIP file to be checked.
     * @returns {Promise<{missingFiles: string[], missingExtensions: string[], unexpected: string[], matchedExpected : string[]}>}
     * An array of missing files, missing extensions, unexpected files, and matched expected files.
     */
    const checkZipContent = async (zipFile) => {
        let expectedFilenames = [];
        let expectedExtensions = [];

        switch (frameworkRef.current) {
            case Framework.DOCKER:
                expectedFilenames = ["Dockerfile"];
                expectedExtensions = [];
                break;
            case Framework.STREAMLIT:
            case Framework.PANEL:
            case Framework.SOLARA:
            case Framework.FLASK:
                expectedFilenames = ["app.py", "requirements.txt"];
                break;
            case Framework.CHAINLIT:
                expectedFilenames = ["app.py", "requirements.txt"];
                break;
            case Framework.DASH:
                expectedFilenames = ["app.py", "requirements.txt"];
                break;
            case Framework.SHINY_R:
                expectedFilenames = ["startApp.R", "install.R", "app.R"];
                break;
            case Framework.VOILA:
                expectedFilenames = ["app.ipynb", "requirements.txt"];
                break;
            default:
                break;
        }

        let zip;
        let filesInZip;

        try {
            zip = await JSZip.loadAsync(zipFile);
            filesInZip = Object.keys(zip.files);
        } catch (error) {
            // file corrupt
            return {
                missingFiles: expectedFilenames,
                missingExtensions: expectedExtensions,
                unexpected: [],
                matchedExpected: [],
            };
        }
        const missingFiles = [];
        expectedFilenames.forEach((filename) => {
            if (!filesInZip.some((file) => file.endsWith(filename))) {
                missingFiles.push(filename);
            }
        });

        const missingExtensions = [];
        expectedExtensions.forEach((extension) => {
            if (!filesInZip.some((file) => file.endsWith(extension))) {
                missingExtensions.push(extension);
            }
        });

        const unexpected = filesInZip.filter(
            (file) =>
                !expectedFilenames.some((filename) => file.endsWith(filename))
        );

        const matchedExpected = filesInZip.filter((file) =>
            expectedFilenames.some((filename) => file.endsWith(filename))
        );
        return { missingFiles, missingExtensions, unexpected, matchedExpected };
    };

    const handleZipFileUpload = async (zipFile) => {
        const { missingFiles, missingExtensions } = await checkZipContent(
            zipFile,
            frameworkRef.current
        );
        if (missingFiles.length === 0 && missingExtensions.length === 0) {
            setInstruction(UploadInstruction.VALID_ZIP);
            setInstructionType(InstructionType.SUCCESS);
            handleZippedFileInputChange(zipFile);
        } else {
            const errorMessage = templateErrorMessage(
                missingFiles,
                missingExtensions
            );
            setInstruction(errorMessage);
            setInstructionType(InstructionType.ERROR);
        }
    };

    const handleIPYNBAndRequirementsUpload = (ipynbFile, requirementsFile) => {
        setInstruction(UploadInstruction.VALID_IPYNB_AND_REQUIREMENTS);
        setInstructionType(InstructionType.SUCCESS);
        handleNotebookFileInputChange(ipynbFile);
        handleRequirementsFileInputChange(requirementsFile);
    };

    /**
     * Runs an analysis on the uploaded files to identify potential issues
     * Temporary: Return success when any error is thrown (500, timeout, etc)
     */
    async function analyzeUpload(zipFiles) {
        setInstructionZipfile([UploadInstruction.PROMPT_ZIPFILE_ANALYSIS]);
        setInstructionTypeZipfile([InstructionType.LOADING]);

        const formData = new FormData();
        formData.append("file", zipFiles[0]);
        formData.append("framework", frameworkRef.current);

        try {
            const result = await ploomberAPI.analyzeZipfile(formData, 7000);
            if (result.errors && result.errors.length > 0) {
                setInstructionZipfile(
                    result.errors.map((error) => error.message)
                );
                setInstructionTypeZipfile(
                    result.errors.map((error) => error.type)
                );
            } else {
                setInstructionZipfile(["Analysis passed!"]);
                setInstructionTypeZipfile([InstructionType.SUCCESS]);
            }
        } catch (error) {
            console.error("Error during file analysis: ", error);
            setInstructionZipfile(["Analysis passed!"]);
            setInstructionTypeZipfile([InstructionType.SUCCESS]);
        }
    }

    /**
     * Handles changes in the file input.
     * Validates the file combination and sets appropriate messages.
     * @param {Array} newFiles - The array of newly dropped files
     */
    const handleFilesChange = useCallback(
        (newFiles) => {
            const updatedDroppedFiles = [
                ...droppedFilesRef.current,
                ...newFiles,
            ];
            setDroppedFiles(updatedDroppedFiles);

            // Reset instruction to initial instruction when files are removed
            if (!updatedDroppedFiles || updatedDroppedFiles.length === 0) {
                reset();
            }

            // Filtering files based on their types
            const zipFiles = updatedDroppedFiles.filter((file) =>
                file.name.endsWith(".zip")
            );
            const ipynbFiles = updatedDroppedFiles.filter((file) =>
                file.name.endsWith(".ipynb")
            );
            const requirementsFiles = updatedDroppedFiles.filter(
                (file) => file.name === "requirements.txt"
            );
            // For frameworks that support both (e.g. voila)
            if (isZippedBased && isIPYNBAndRequirementsBased) {
                // Uploading a single zip file
                if (zipFiles.length === 1 && updatedDroppedFiles.length === 1) {
                    handleZipFileUpload(zipFiles[0]);
                } else if (
                    // Uploading ipynb and requirements files
                    ipynbFiles.length === 1 &&
                    requirementsFiles.length === 1 &&
                    updatedDroppedFiles.length === 2
                ) {
                    handleIPYNBAndRequirementsUpload(
                        ipynbFiles[0],
                        requirementsFiles[0]
                    );
                } else {
                    setInstruction(
                        UploadInstruction.INVALID_ZIP_OR_IPYNB_AND_REQUIREMENTS
                    );
                    setInstructionType(InstructionType.ERROR);
                }
            } else if (isZippedBased) {
                // Uploading a single zip file
                if (zipFiles.length === 1 && updatedDroppedFiles.length === 1) {
                    handleZipFileUpload(zipFiles[0], frameworkRef.current);

                    // analyze the zip file
                    analyzeUpload(zipFiles);
                } else {
                    setInstruction(UploadInstruction.INVALID_ZIP);
                    setInstructionType(InstructionType.ERROR);
                }
            } else if (isIPYNBAndRequirementsBased) {
                if (
                    // Uploading ipynb and requirements files
                    ipynbFiles.length === 1 &&
                    requirementsFiles.length === 1 &&
                    updatedDroppedFiles.length === 2
                ) {
                    handleIPYNBAndRequirementsUpload(
                        ipynbFiles[0],
                        requirementsFiles[0]
                    );
                } else {
                    setInstruction(
                        UploadInstruction.INVALID_IPYNB_AND_REQUIREMENTS
                    );
                    setInstructionType(InstructionType.ERROR);
                }
            } else {
                setInstruction(UploadInstruction.UNEXPECTED_ERROR);
                setInstructionType(InstructionType.ERROR);
            }
        },
        [droppedFiles]
    );

    useEffect(() => {
        reset();
        frameworkRef.current = framework;
        if (templateFiles.length > 0) {
            setDroppedFiles(templateFiles);
            handleFilesChange(templateFiles);
        }
    }, [templateFiles]);

    return (
        <BlockTable
            keys={[{ style: { padding: 0 } }]}
            values={[
                [
                    <div className="inline-elements-container">
                        <FileDropzone
                            accept={acceptedMIME}
                            maxFiles={2}
                            id="upload-file-input"
                            dataTestId="upload-file-input"
                            onDrop={handleFilesChange}
                            droppedFiles={droppedFiles}
                            setDroppedFiles={setDroppedFiles}
                            reset={reset}
                            label="Upload application files"
                        />
                    </div>,
                ],
                [
                    <div data-testid="filetype-validation-message">
                        <UserInstruction
                            instructionType={instructionType}
                            instruction={instruction}
                        />
                    </div>,
                ],
                instructionTypeZipfile !== "empty"
                    ? [
                          <div data-testid="zipfile-analysis-message">
                              {instructionZipfile.map((msg, idx) => (
                                  <UserInstruction
                                      id={idx}
                                      instructionType={
                                          instructionTypeZipfile[idx]
                                      }
                                      instruction={msg}
                                  />
                              ))}
                          </div>,
                      ]
                    : [],
            ]}
        />
    );
};

UploadNewApplicationFiles.propTypes = {
    fileError: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }).isRequired,
    handleNotebookFileInputChange: PropTypes.func.isRequired,
    handleRequirementsFileInputChange: PropTypes.func.isRequired,
    handleZippedFileInputChange: PropTypes.func.isRequired,
    framework: PropTypes.string.isRequired,
    templateFiles: PropTypes.arrayOf(PropTypes.instanceOf(File)),
};

UploadNewApplicationFiles.defaultProps = {
    templateFiles: [],
};

export default UploadNewApplicationFiles;
