import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPricingsDialog = styled(Dialog)(({ theme }) => ({
    color: theme.palette.neutral.black,
    fontSize: "1rem",
    textAlign: "center",
    "& .PageTitle": {
        textAlign: "center",
    },
    "& .PlansContainer": {
        margin: "auto",
        marginTop: theme.spacing(2),
        display: "grid",
        gridTemplateColumns: "auto auto auto auto",
        gap: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    ".PricingPlan": {
        border: `1px solid ${theme.palette.neutral.grey}`,
        borderRadius: "1.5rem",
        padding: theme.spacing(1.5),
        width: 300,
    },
    ".PricingPlan.Selected": {
        border: "1px solid #687382",
    },
    ".PricingPlan.Selected .Badge": {
        backgroundColor: "#687382",
        width: 200,
        margin: "auto",
        color: "#fff",
        marginTop: -27,
        border: "3px solid #fff",
        borderRadius: "1rem",
    },
    ".PricingPlan .Title": {
        marginTop: 20,
        fontWeight: 700,
        height: 50,
    },
    ".PricingPlan .PriceContainer > div": {
        height: 150,
        margin: "0 auto",
    },
    ".PricingPlan .PriceContainer .Small": {
        fontSize: "1rem",
        color: "#64748b",
    },
    ".PricingPlan .PriceContainer .Price": {
        fontSize: "2.5rem",
        fontWeight: 600,
    },
    ".PricingPlan .PriceContainer .CustomPricing": {
        fontSize: "2.5rem",
    },
    ".PricingPlan .Features": {
        textAlign: "left",
        height: 300,
        display: "flex",
        flexDirection: "column",
        marginBottom: 15,
    },
    ".PricingPlan .Features .Feature": {
        display: "flex",
        alignItems: "center",
    },
    ".PricingPlan .Features .Feature .Icon": {
        margin: "auto 10px",
    },
    ".PricingPlan .Features .Feature .Icon *": {
        color: "#F9C32B",
        fontSize: "1.2rem",
    },
    ".PricingPlan [id*='upgradeButton']": {
        backgroundColor: "#f9c32b",
        width: "100%",
        height: 50,
        color: "#000",
    },

    ".PricingPlan .Preselected": {
        animation: "pulse 1.5s infinite",
        backgroundColor: "#ffd700 !important", // Brighter gold color
        transition: "all 0.3s ease",
        border: "3px solid #f9c32b",
        "@keyframes pulse": {
            "0%": {
                transform: "scale(1)",
                boxShadow: "0 8px 20px rgba(249, 195, 43, 0.4)",
            },
            "50%": {
                transform: "scale(1.08)",
                boxShadow: "0 12px 28px rgba(249, 195, 43, 0.6)",
            },
            "100%": {
                transform: "scale(1)",
                boxShadow: "0 8px 20px rgba(249, 195, 43, 0.4)",
            },
        },
    },

    ".PricingPlan.Selected [id*='upgradeButton']": {
        backgroundColor: theme.palette.neutral.grey,
        color: theme.palette.neutral.black,
    },
    "& .MuiDialogTitle-root": {
        display: "flex",
        alignItems: "center",
    },
    "& .MuiDialogTitle-root .CloseIcon": {
        marginLeft: "auto",
    },

    // pricing table
    ".TableContainer": {
        margin: "50px auto",
        maxWidth: "1200px",
        padding: "0 20px",
    },
    ".TableContainer table": {
        width: "100%",
        borderCollapse: "collapse",
        textAlign: "center",
    },
    ".TableContainer th, .TableContainer td": {
        padding: "15px",
        borderBottom: "1px solid #e2e8f0",
    },
    ".TableContainer th": {
        fontWeight: 600,
        fontSize: "1.1rem",
        color: theme.palette.neutral.black,
    },
    ".TableContainer td": {
        color: "#64748b",
    },
    ".TableContainer td:first-child": {
        textAlign: "left",
        fontWeight: 500,
        color: theme.palette.neutral.black,
    },
    ".TableContainer td:not(:first-child)": {
        width: "20%",
    },

    ".TableContainer tr:hover": {
        backgroundColor: "#f8fafc",
    },
}));

export default StyledPricingsDialog;
