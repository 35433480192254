import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../context/AppContext";

function NavigateWithParams({ to }) {
    const { navigateWithSearchParams } = useContext(AppContext);

    useEffect(() => {
        setTimeout(() => {
            navigateWithSearchParams(to);
        }, 500);
    }, []);

    return "";
}

export default NavigateWithParams;
