import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSetProjectLabelsController = styled(Box)(({ theme }) => ({
    "& .WithEditButton .NoLabelsMessage": {
        width: "fit-content",
        margin: "auto 0",
        color: theme.palette.neutral?.back,
    },
    "& .WithEditButton .TooltipChildrenContainer": {
        margin: "initial !important",
    },
    "& .WithEditButton, & .WithEditButton fieldset": {
        borderColor: "transparent !important",
    },
    "& .disabled, & .disabled fieldset": {
        borderColor: "transparent !important",
        width: "0",
    },
    "& .disabled .MuiInputBase-input": {
        visibility: "hidden",
        padding: "10px 0 !important",
    },
    "& .disabled .MuiChip-deleteIcon": {
        display: "none",
    },
    "& .MuiChip-root": {
        marginRight: theme.spacing(0.5),
    },
    ".JobStatus &": {
        width: "50%",
    },
}));

export default StyledSetProjectLabelsController;
