import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { IconButton, Menu, MenuItem } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AppContext } from "../../../context/AppContext";

export default function APIKeyActionsRenderer(props) {
    const { user, updateSnackbarStatus } = useContext(AppContext);

    const { data, handleDelete, isLoadingSpinner } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteKey = () => {
        handleDelete(data.api_key);
        handleClose();
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(data.api_key);
        updateSnackbarStatus({
            message: "Copied!!",
        });
    };

    const disabledStyle = {
        opacity: 0.5,
        pointerEvents: "none",
    };

    return (
        <div
            style={isLoadingSpinner ? disabledStyle : {}}
            data-testid={`${data.api_key}-action-button`}
        >
            <IconButton
                size="small"
                onClick={handleCopy}
                data-testid={`${data.api_key}-copy-button`}
            >
                <ContentCopyIcon fontSize="small" color="primary" />
            </IconButton>
            <IconButton
                size="small"
                onClick={handleDeleteKey}
                data-testid={`${data.api_key}-delete-button`}
            >
                <DeleteForeverIcon fontSize="small" color="primary" />
            </IconButton>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
        </div>
    );
}

APIKeyActionsRenderer.propTypes = {
    data: PropTypes.shape({
        api_key: PropTypes.string.isRequired,
    }).isRequired,
    handleDelete: PropTypes.func.isRequired,
    isLoadingSpinner: PropTypes.bool.isRequired,
};
