import { Alert } from "@mui/material";
import React, {
    useState,
    createContext,
    useMemo,
    useContext,
    useEffect,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../components/Loader";
import { AccountContext } from "../features/user/Account";
import { parseErrorMessage, b64toBlob } from "../utils/utils.ts";
import StyledSnackBar from "../styles/layouts/Snackbar.styled";
import ploomberAPI from "../services/ploomberAPI.ts";

const AppContext = createContext();

const emptySnackbarStatus = {
    message: "",
    severity: "info",
    vertical: "top",
    horizontal: "center",
    duration: 4000,
};

const getAvatar = () => {
    try {
        const avatarData = localStorage.getItem("avatar");
        if (avatarData) {
            const { url, blob } = JSON.parse(avatarData);
            const blobObject = b64toBlob(blob);
            if (blobObject) {
                const avatarUrl = URL.createObjectURL(blobObject);
                return avatarUrl;
            }
        }
        return null;
    } catch (err) {
        console.error("Error getting the avatar URL:", err);
        return null;
    }
};

function AppContextProvider(props) {
    const { logout, getSession, authed } = useContext(AccountContext);
    const accountContext = useContext(AccountContext);

    // eslint-disable-next-line react/prop-types
    const { children } = props;
    const [user, setUser] = useState({});

    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [snackbarStatus, updateSnackbarStatus] =
        useState(emptySnackbarStatus);

    const [uploadingFile, setUploadingFile] = useState({
        show: false,
        inner: {},
    });

    const [creditInfo, setCreditInfo] = useState({
        allotted_credits: 0,
        used_credits: 0,
        billing_end: "",
        usage_by_app: {},
        loading: true,
    });

    const navigateWithSearchParams = (to, options) => {
        const hasQueryParams = searchParams?.toString()?.length > 0;
        let navigateTo = to;

        if (hasQueryParams) {
            navigateTo += `?${searchParams.toString()}`;
        }
        navigate(navigateTo, options);
    };

    const handleCloseSnackbar = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        updateSnackbarStatus(emptySnackbarStatus);
    };

    const fetchCreditUsage = async () => {
        try {
            setCreditInfo({ ...creditInfo, loading: true });
            const response = await ploomberAPI.getUserCredits();
            setCreditInfo({ ...response, loading: false });
        } catch (err) {
            updateSnackbarStatus({
                message: parseErrorMessage(err),
                severity: "error",
            });
            setCreditInfo({ ...creditInfo, loading: false });
        }
    };

    const initUser = async () => {
        try {
            const session = await getSession();
            if (session) {
                const {
                    id,
                    email,
                    type,
                    onboarded,
                    avatar_url: avatar,
                } = session;

                const avatarData = localStorage.getItem("avatar");
                const { url, blob } = avatarData
                    ? JSON.parse(avatarData)
                    : { url: "", blob: "" };

                if (!avatar) {
                    localStorage.removeItem("avatar");
                } else if (avatar && (avatar !== url || !avatarData)) {
                    try {
                        // this logic doesnt work in localhost (429 error/ CORS issue)
                        // so it will default to the fallback avatar
                        const imageResponse = await fetch(avatar);
                        if (imageResponse.ok === true) {
                            const imageBlob = await imageResponse.blob();
                            const reader = new FileReader();
                            reader.readAsDataURL(imageBlob);
                            reader.onloadend = function () {
                                const base64data = reader.result;
                                localStorage.setItem(
                                    "avatar",
                                    JSON.stringify({
                                        url: avatar,
                                        blob: base64data,
                                    })
                                );
                            };
                        }
                    } catch (err) {
                        // CORS Issue with Google Enterprise account
                        console.error(`Not able to fetch avatar: ${err}`);
                    }
                }

                const fallbackAvatar = email[0].toUpperCase();

                setUser({
                    id,
                    fallbackAvatar,
                    email,
                    type,
                    onboarded,
                });
            }
            return Promise.resolve(true);
        } catch (err) {
            updateSnackbarStatus({
                message: parseErrorMessage(err),
                severity: "error",
            });

            return Promise.resolve(false);
        }
    };

    const appProviderValue = useMemo(
        () => ({
            navigateWithSearchParams,
            navigate,
            location,
            searchParams,
            updateSnackbarStatus,
            setUploadingFile,
            logout,
            user,
            accountContext,
            initUser,
            creditInfo,
        }),
        [location, user, creditInfo]
    );

    useEffect(() => {
        if (authed) {
            initUser();
            fetchCreditUsage();
        }
    }, [authed]);

    return (
        <AppContext.Provider value={appProviderValue}>
            {uploadingFile.show && <Loader inner={uploadingFile.inner} />}

            <StyledSnackBar
                data-testid="snackbar"
                anchorOrigin={{
                    vertical: snackbarStatus.vertical || "top",
                    horizontal: snackbarStatus.horizontal || "center",
                }}
                open={Boolean(snackbarStatus.message)}
                autoHideDuration={snackbarStatus.duration}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarStatus.severity}
                >
                    {snackbarStatus.message}
                </Alert>
            </StyledSnackBar>

            {children}
        </AppContext.Provider>
    );
}

export { AppContext, AppContextProvider, getAvatar };
