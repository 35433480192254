import React from "react";
import * as uuid from "uuid";
import PropTypes from "prop-types";
import { FormControl, MenuItem, Select as SelectMUI } from "@mui/material";

function Select({
    options,
    style,
    defaultValue,
    selectedValue,
    disabled,
    onChange,
    onOptionClick,
    id,
    onOpen,
}) {
    const value = selectedValue || defaultValue || options[0];
    return (
        <FormControl style={style}>
            <SelectMUI
                labelId="select-label"
                id={id}
                value={value}
                onChange={onChange}
                disabled={disabled}
                onOpen={onOpen}
            >
                {["Select", ...options].map((option, index) => {
                    const text =
                        typeof option === "string" ? option : option.text;
                    const selectorValue =
                        typeof option === "string" ? option : option.value;
                    const disabledOption = option?.disabled || false;
                    if (index === 0) {
                        return (
                            <MenuItem key={option} value="" disabled>
                                <em>{option}</em>
                            </MenuItem>
                        );
                    }

                    return (
                        <MenuItem
                            key={text}
                            value={selectorValue}
                            disabled={
                                disabledOption && onOptionClick === undefined
                            }
                            onClick={
                                onOptionClick !== undefined
                                    ? () => onOptionClick(option)
                                    : null
                            }
                        >
                            {text}
                        </MenuItem>
                    );
                })}
            </SelectMUI>
        </FormControl>
    );
}

Select.defaultProps = {
    style: {},
    defaultValue: undefined,
    selectedValue: undefined,
    disabled: false,
    onChange: () => {},
    onOptionClick: undefined,
    onOpen: () => {},
    id: uuid.v4(),
};

Select.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                disabled: PropTypes.bool,
            }),
        ])
    ).isRequired,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    defaultValue: PropTypes.string,
    selectedValue: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onOptionClick: PropTypes.func,
    id: PropTypes.string,
    onOpen: PropTypes.func,
};

export default Select;
