import React, { useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { AccountContext } from "../../../features/user/Account";
import { AppContext } from "../../../context/AppContext";
import { isValidPloomberUrl } from "./SigninPage";

function SocialLoginProxyPage(props) {
    const accountContext = useContext(AccountContext);
    const { navigate, location, initUser, updateSnackbarStatus } =
        useContext(AppContext);
    const [searchParams] = useSearchParams();

    const redirectToSignIn = (errorMessage = "Error with social login") => {
        navigate("/signin");
        updateSnackbarStatus({
            message: errorMessage,
            severity: "error",
        });
    };

    const signInFromSocial = async (authCode) => {
        const { authenticateFromSocial } = accountContext;
        authenticateFromSocial(
            authCode,
            `${window.location.protocol}//${window.location.host}/social-login-proxy/`
        )
            .then(async (data) => {
                await initUser();
                const redirect = localStorage.getItem("redirect");
                if (
                    redirect &&
                    isValidPloomberUrl(decodeURIComponent(redirect))
                ) {
                    window.location.href = decodeURIComponent(redirect);
                }
                navigate(searchParams.get("returnUrl") || "/");
            })
            .catch((err) => {
                redirectToSignIn(err?.detail || err?.message);
            });
    };

    useEffect(() => {
        const code = searchParams.get("code");
        const state = searchParams.get("state");

        if (code && localStorage.getItem("socialLoginState") === state) {
            signInFromSocial(code);
        } else {
            redirectToSignIn();
        }
    }, []);

    return <div className="public-view-style">Redirecting</div>;
}

export default SocialLoginProxyPage;
