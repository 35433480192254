import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledConfirmSubscriptionCancellationDialog = styled(Dialog)(
    ({ theme }) => ({
        height: "70%",
        "& .MuiDialogTitle-root": {
            fontSize: "1.5rem",
            padding: "1.5rem",
            paddingBottom: "1.5rem",
        },
        "& .MuiDialogContent-root": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        "& .MuiDialogActions-root": {
            padding: "1rem",
            justifyContent: "space-between",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        "& .MuiMobileStepper-root": {
            flexGrow: 1,
            paddingTop: "1rem",
            display: "flex",
            justifyContent: "center",
            width: "100%",
        },
        "& .Subtitle": {
            marginBottom: "0rem",
        },
        "& .ListItems": {
            padding: "0.3rem",
            paddingLeft: "0rem",
        },
        "& .Footer": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
        },
        "& .Buttons": {
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
        },
        "& .ScrollableBox": {
            height: "100%",
            overflowY: "auto",
            padding: "0rem",
            paddingLeft: "0rem",
        },
        "& .MuiFormControlLabel-label": {
            fontSize: "1.0rem",
        },
        "& .MuiSvgIcon-root": {
            fontSize: 20,
        },
    })
);

export default StyledConfirmSubscriptionCancellationDialog;
