import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
    CircularProgress,
    InputAdornment,
    FormControl,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import ControllerLabel from "../../../components/UI/ControllerLabel";
import { AppContext } from "../../../context/AppContext";
import { CustomError } from "../../../utils/utils.ts";
import { AccountContext } from "../../user/Account";
import StyleTextFieldWithLoader from "../../../styles/components/UI/TextFieldWithLoader.Styled";

const MAX_LENGTH = 50;
const MIN_LENGTH = 1;

const FEATURE_ID = "selectStorage";

function SetStorageController({
    onChange,
    storageEnabled,
    onToggle,
    previousStorageValue,
}) {
    const { updateSnackbarStatus } = useContext(AppContext);
    const { canUserAccessComponent, userType } = useContext(AccountContext);
    const [disabled, setDisabled] = useState();
    const [isToggleOn, setIsToggleOn] = useState(false);
    const [storageValue, setStorageValue] = useState();

    useEffect(() => {
        const canAccess = canUserAccessComponent(FEATURE_ID);
        setDisabled(!canAccess);
    }, [userType]);

    useEffect(() => {
        setIsToggleOn(storageEnabled);
        onToggle(storageEnabled);
        if (storageEnabled) {
            if (previousStorageValue) {
                setStorageValue(previousStorageValue);
            }
        } else {
            setStorageValue(0);
        }
    }, [storageEnabled]);

    const handleSwitchChange = (e) => {
        const isChecked = e.target.checked;
        setIsToggleOn(isChecked);
        onToggle(isChecked);
        if (!isChecked) {
            setStorageValue(0); // Clear storage value when toggled off
            onChange(0); // clear the value in the parent when toggled off
        }
    };

    const [error, setError] = useState(false);
    const [inputIcon, setInputIcon] = useState();

    function validateStorage(value) {
        const storageNumber = Number(value);
        if (storageNumber < MIN_LENGTH || storageNumber > MAX_LENGTH) {
            const err = `Storage amount must be between ${MIN_LENGTH} and ${MAX_LENGTH} GBs.`;
            updateSnackbarStatus({
                message: err,
                severity: "error",
                duration: 5000,
            });
            throw new CustomError(err);
        }
    }

    const handleInputBlur = async (e) => {
        const { value } = e.target;
        if (!value) {
            setInputIcon();
            setError(false);
            return;
        }

        try {
            setInputIcon(<CircularProgress />);
            validateStorage(value);
            setError(false);
            setTimeout(() => {
                setInputIcon(<CheckCircleIcon color="success" />);
            }, 100);
            onChange(value);
            setStorageValue(Number(value));
        } catch (err) {
            setError(true);
            onChange(0);
            setTimeout(() => {
                setInputIcon(<CancelIcon color="error" />);
            }, 100);
        } finally {
            setInputIcon();
        }
    };

    const calculatePrice = (storage) => {
        const pricePerGB = 0.16;
        return (storage * pricePerGB).toFixed(2);
    };

    return (
        <div className="Controller" feature-id={FEATURE_ID}>
            <FormControl
                component="fieldset"
                variant="standard"
                disabled={disabled}
                sx={{ alignItems: "flex-start" }}
            >
                <ControllerLabel
                    text="Amount of storage (GBs)"
                    featureId={FEATURE_ID}
                />
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                id="toggle-persistent-storage"
                                checked={isToggleOn}
                                onChange={(e) => {
                                    setIsToggleOn(e.target.checked);
                                    handleSwitchChange(e);
                                }}
                                color="info"
                            />
                        }
                        label="Use persistent storage"
                        labelPlacement="start"
                        sx={{
                            margin: 0,
                            width: "fit-content",
                        }}
                    />
                </FormGroup>
            </FormControl>

            {isToggleOn && (
                <div>
                    <StyleTextFieldWithLoader
                        data-testid="select-storage"
                        disabled={disabled}
                        error={error}
                        placeholder="Persistent storage (GBs)"
                        onBlur={handleInputBlur}
                        type="number"
                        defaultValue={storageValue}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {inputIcon}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormHelperText data-testid="storage-help-text">
                        ${calculatePrice(storageValue)} per month
                    </FormHelperText>
                </div>
            )}
        </div>
    );
}

SetStorageController.defaultProps = {
    storageEnabled: false,
    previousStorageValue: 0,
    onToggle: () => {},
};

SetStorageController.propTypes = {
    onChange: PropTypes.func.isRequired,
    storageEnabled: PropTypes.bool,
    onToggle: PropTypes.func,
    previousStorageValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

export default SetStorageController;
