import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

function getColor(instructionType, theme) {
    switch (instructionType) {
        case "success":
            return theme.palette.success.main;
        case "error":
            return theme.palette.error.main;
        default:
            return theme.palette.neutral.black;
    }
}

const StyledUserInstruction = styled(Box)(
    ({ theme, instructionType, instruction }) => ({
        instruction,
        marginTop: "10px",
        display: "flex",
        alignItems: "center",
        color: getColor(instructionType, theme),
        "& > span": {
            fontSize: theme.fonts.size.small,
        },
        "& p": {
            marginLeft: "15px",
        },
    })
);

export default StyledUserInstruction;
