import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const DebugActionGroup = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    width: "100%",
    paddingLeft: "1.7rem",
    paddingRight: "1.7rem",
}));

export default DebugActionGroup;
