import { CertificateStatus, JobStatus } from "../models/enum.ts";

/**
 * Creates a downloadable link for a given blob and triggers a download.
 *
 * @param title - The title to be used for the downloaded file (without extension).
 * @param blob - The Blob object representing the file content to be downloaded.
 */
export const createDownload = (title, blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${title}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
};

export const togglePasswordVisibility = (ref: any): void => {
    const input = ref;
    const icon = input?.current?.parentElement?.querySelector("i");

    const visiblePasswordIcon = "far fa-eye fa-eye-slash";
    const hiddenPasswordIcon = "far fa-eye";
    const isVisible = ref.current.type === "text";

    if (isVisible) {
        input.current.type = "password";
        icon.className = hiddenPasswordIcon;
    } else {
        input.current.type = "text";
        icon.className = visiblePasswordIcon;
    }
};

/**
 * Returns chip color based on JobStatus
 * For more information about availble color values:
 * https://mui.com/material-ui/api/chip/#Chip-prop-color
 *
 * @param jobStatus
 * @returns chip color
 */
export const getJobStatusChipColor = (jobStatus: string): string => {
    let status: string;

    switch (jobStatus) {
        case JobStatus.PENDING:
            status = "info";
            break;
        case JobStatus.CREATED:
            status = "info";
            break;
        case JobStatus.STOPPING:
            status = "warning";
            break;
        case JobStatus.RUNNING:
            status = "success";
            break;
        case JobStatus.NOT_DEPLOYED:
            status = "default";
            break;
        default:
            status = "error";
    }

    return status;
};

/**
 * Returns chip color based on JobStatus
 * For more information about availble color values:
 * https://mui.com/material-ui/api/chip/#Chip-prop-color
 *
 * @param jobStatus
 * @returns chip color
 */
export const getCustomDomainStatusChipColor = (jobStatus: string): string => {
    let status: string;

    switch (jobStatus) {
        case CertificateStatus.ISSUED:
            status = "success";
            break;
        case CertificateStatus.PENDING_VALIDATION:
            status = "default";
            break;
        default:
            status = "error";
    }

    return status;
};
