import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    MenuItem,
    Select,
    Typography,
    Box,
    TextField,
    Grid,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import icon from "../assets/icon.png";
import telemetry from "../services/telemetry.ts";

const SIGN_UP_SURVEY_ID = "SIGNUP-PLATFORM";
const PURPOSES = [
    "Analytics Dashboard",
    "LLM-Powered Applications",
    "Web Applications",
    "Containerized Applications",
    "Scheduled Jobs",
    "Background Tasks",
];

export default function SignUpSurvey({ open, setOpen, onCloseCallback }) {
    const [usage, setUsage] = useState("");
    const [hearAbout, setHearAbout] = useState("");
    const [purpose, setPurpose] = useState([]);
    const [otherPurpose, setOtherPurpose] = useState("");
    const [error, setError] = useState("");

    const handleClose = (_, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
        }
        setOpen(false);
        setError("");
        onCloseCallback();
    };

    const handleSubmit = () => {
        if (purpose.length === 0) {
            setError("Please select at least one purpose.");
            return;
        }
        const surveyData = {
            usage,
            hearAbout,
            purpose: purpose.filter((item) => item !== "Other"),
            otherPurpose: purpose.includes("Other") ? otherPurpose : null,
        };
        telemetry.saveSurveyAnwser(SIGN_UP_SURVEY_ID, surveyData);
        handleClose();
    };

    const handleHearAboutChange = (event) => setHearAbout(event.target.value);
    const handlePurposeChange = (value) => {
        setPurpose(
            purpose.includes(value)
                ? purpose.filter((item) => item !== value)
                : [...purpose, value]
        );
        setError("");
    };

    const handleOtherPurposeChange = (event) => {
        setOtherPurpose(event.target.value);
        if (!purpose.includes("Other")) {
            setPurpose([...purpose, "Other"]);
        }
        setError("");
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="signup-survey-dialog"
                maxWidth="sm"
                fullWidth
                data-testid="signup-survey-dialog"
            >
                <DialogTitle
                    id="signup-survey-dialog"
                    style={{ textAlign: "center" }}
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="10px"
                        fontWeight="700"
                    >
                        <img
                            src={icon}
                            alt="Ploomber Logo"
                            style={{
                                marginRight: "10px",
                                height: "24px",
                            }}
                        />
                        Welcome to Ploomber!
                    </Box>
                </DialogTitle>
                <DialogContent style={{ paddingBottom: "0px" }}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                            marginTop: "20px",
                            textAlign: "center",
                            fontSize: "1rem",
                        }}
                    >
                        How are you planning to use Ploomber?
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="center"
                        gap="10px"
                        marginBottom="20px"
                        width="100%"
                    >
                        <Button
                            variant={
                                usage === "personal" ? "contained" : "outlined"
                            }
                            onClick={() => setUsage("personal")}
                            startIcon={<PersonIcon />}
                            fullWidth
                            data-testid="survey-personal-use-button"
                            style={{ textTransform: "none" }}
                        >
                            Personal Use
                        </Button>
                        <Button
                            variant={
                                usage === "work" ? "contained" : "outlined"
                            }
                            onClick={() => setUsage("work")}
                            startIcon={<WorkIcon />}
                            fullWidth
                            data-testid="survey-work-use-button"
                            style={{ textTransform: "none" }}
                        >
                            Work
                        </Button>
                    </Box>

                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                            marginTop: "20px",
                            textAlign: "center",
                            fontSize: "1rem",
                        }}
                    >
                        How did you hear about us?
                    </Typography>
                    <FormControl fullWidth style={{ marginBottom: "20px" }}>
                        <Select
                            labelId="hear-about-label"
                            value={hearAbout}
                            onChange={handleHearAboutChange}
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected === "") {
                                    return <em>Select an option</em>;
                                }
                                return selected;
                            }}
                            style={{ padding: "5px" }}
                            data-testid="survey-select-hear-about"
                            data-value={hearAbout}
                        >
                            <MenuItem value="">
                                <em>Select an option</em>
                            </MenuItem>
                            <MenuItem value="search">Search Engine</MenuItem>
                            <MenuItem value="social">Social Media</MenuItem>
                            <MenuItem value="friend">
                                Friend Recommendation
                            </MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>
                    </FormControl>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{ textAlign: "center", fontSize: "1rem" }}
                    >
                        What do you want to use Ploomber for?
                    </Typography>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                    >
                        <Grid container spacing={1}>
                            {PURPOSES.map((item) => (
                                <Grid item xs={12} sm={6} key={item}>
                                    <Button
                                        fullWidth
                                        style={{ textTransform: "none" }}
                                        data-testid={`survey-purpose-select-${item}`}
                                        variant={
                                            purpose.includes(item)
                                                ? "contained"
                                                : "outlined"
                                        }
                                        onClick={() =>
                                            handlePurposeChange(item)
                                        }
                                    >
                                        {item}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                        <TextField
                            placeholder="Other"
                            InputLabelProps={{ shrink: false }}
                            variant="outlined"
                            value={otherPurpose}
                            onChange={handleOtherPurposeChange}
                            data-testid="survey-purpose-enter-other"
                            style={{
                                flexBasis: "100%",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }}
                        />
                    </Box>
                    <Typography
                        color="error"
                        style={{
                            marginTop: "10px",
                            textAlign: "center",
                            height: "2rem",
                        }}
                    >
                        {error}
                    </Typography>
                </DialogContent>
                <DialogActions
                    style={{
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: "20px",
                    }}
                >
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        data-testid="submit-survey-button"
                        style={{
                            width: "250px",
                            marginBottom: "10px",
                            textTransform: "none",
                        }}
                    >
                        Let&apos;s get started 🎉
                    </Button>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        data-testid="skip-survey-button"
                        style={{ textTransform: "none", fontSize: "0.875rem" }}
                    >
                        Skip for now
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

SignUpSurvey.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onCloseCallback: PropTypes.func.isRequired,
};
