import React, { createContext, useMemo } from "react";
import PropTypes from "prop-types";
import ploomberAPI from "../../services/ploomberAPI.ts";

const ApplicationSettingsContext = createContext();

function ApplicationSettingsProvider({ children }) {
    const handleUpdateProjectLabels = async (
        projectId,
        newLabels,
        setJobLabels,
        updateSnackbarStatus
    ) => {
        try {
            await ploomberAPI.updateProjectLabels(projectId, newLabels);
            updateSnackbarStatus({
                message: `Labels updated successfully`,
            });

            setJobLabels(newLabels);
        } catch (error) {
            updateSnackbarStatus({
                message: `Failed to update labels: ${error}`,
                severity: "error",
            });
        }
    };
    const providerValue = useMemo(
        () => ({
            handleUpdateProjectLabels,
        }),
        [handleUpdateProjectLabels]
    );
    return (
        <ApplicationSettingsContext.Provider value={providerValue}>
            {children}
        </ApplicationSettingsContext.Provider>
    );
}

ApplicationSettingsProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
};

export { ApplicationSettingsContext, ApplicationSettingsProvider };
