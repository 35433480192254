import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import {
    Account as AccountProvider,
    AccountContext,
} from "./features/user/Account";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <AccountProvider>
            <App />
        </AccountProvider>
    </BrowserRouter>
    // </React.StrictMode>
);
