import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDropzone = styled(Box)(({ theme }) => ({
    border: `2px dashed ${theme.palette.neutral.darkGrey}`,
    borderRadius: "5px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    width: "100%",
}));

const StyledFileList = styled("ul")(({ theme }) => ({
    listStyle: "none",
    margin: 0,
    padding: 0,
}));

export { StyledDropzone, StyledFileList };
