import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledCustomDomainRecord = styled(Box)(({ theme }) => ({
    "&": {
        marginTop: theme.spacing(2),
    },

    "& .ExpandableRow": {
        display: "inline-flex",
        gap: theme.spacing(2),
    },

    "& .ExpandableRow .RowItem": {
        margin: "auto 0",
    },

    "& .OpenNSDetailsButton": {},

    "& .ExpansionContainer .DNSInstructions": {
        backgroundColor: theme.palette.neutral.lightGrey,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(2.5),
    },

    "& .ExpansionContainer.Closed": {
        display: "none",
    },

    "& .ExpansionContainer .NSDetailsContainer": {
        width: "100%",
    },

    "& .ExpansionContainer .NSDetailsContainer .NSStatusContainer, & .NSStatusContainer":
        {
            gap: theme.spacing(2),
            display: "inline-flex",
            width: "100%",
        },

    "& .ExpansionContainer .NSDetailsContainer .NSStatusContainer .LabelContainer":
        {
            width: "100%",
        },

    "& .ExpansionContainer .NSDetailsContainer .NSStatusContainer .ActionsContainer":
        {
            margin: "auto 0px",
        },

    "& .ActionButton": {
        margin: "auto 0",
    },

    "& .Mui-disabled.VerifiedButton": {
        color: theme.palette.primary.success,
        borderColor: theme.palette.primary.success,
        backgroundColor: theme.palette.neutral.white,
    },

    "& .UserInstuctionsContainer": {
        width: "50%",
    },

    "& .NSList": {
        listStyle: "none",
        padding: 0,
    },
    "& .DNSTable .MuiButton-startIcon *": {
        fontSize: "0.75rem",
    },
    "& .DNSTable .MuiButtonBase-root:hover": {
        backgroundColor: "transparent",
        color: theme.palette.neutral.link,
    },
    "& .DNSTableContainer, & .Examples": {
        marginBottom: theme.spacing(4),
    },
    "& .DNSStatus": {
        fontSize: "1em",
        fontWeight: "bold",
    },
    "& .DNSStatus .ActionRequired": {
        color: theme.palette.primary.danger,
    },
    "& .DNSStatus .DomainIsReady": {
        color: theme.palette.primary.success,
    },
    "& .SelectDNSConnectionMethodContainer": {
        marginTop: theme.spacing(2),
        width: "100%",
    },

    "& .SelectDNSConnectionMethodContainer .Link": {
        color: theme.palette.neutral.link,
    },
    "& .SelectDNSConnectionMethodContainer .ConnectionMethod": {
        display: "none",
    },
    "& .SelectDNSConnectionMethodContainer .Link:hover": {
        textDecoration: "underline",
    },
    "& .Notes": {
        marginTop: theme.spacing(4),
    },
    "& .CellWithTextAndAction > div": {
        display: "inline-flex",
    },
    "& .CellWithTextAndAction .Text": {
        wordWrap: "break-word",
        width: "100px",
    },
    "& .CellWithTextAndAction .Action": {
        margin: "auto 0",
    },
}));

export default StyledCustomDomainRecord;
