import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

export default function Input({ label, value, id, type, endAdornment }) {
    const disabled = true;

    return (
        <div className="Input">
            <label htmlFor="text-field">
                {/* <span>{label}</span> */}
                <TextField
                    fullWidth
                    id={id}
                    data-testid={id}
                    label={label}
                    variant="outlined"
                    value={value}
                    InputLabelProps={{ shrink: true }}
                    type={type}
                    disabled
                    InputProps={{
                        endAdornment,
                    }}
                />
            </label>
        </div>
    );
}

Input.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    endAdornment: PropTypes.element,
};

Input.defaultProps = {
    value: "",
    type: "text",
    endAdornment: undefined,
};
