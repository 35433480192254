import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";

function HelperTooltip({ text, children, placement }) {
    return (
        <Tooltip
            placement={placement}
            title={text}
            data-testid="helper-tooltip"
            arrow
            slotProps={{
                tooltip: {
                    sx: {
                        bgcolor: "common.black",
                        "& .MuiTooltip-arrow": {
                            color: "common.black",
                        },
                    },
                },
            }}
        >
            {children ? (
                <span
                    className="TooltipChildrenContainer"
                    style={{ margin: "auto 0" }}
                >
                    {children}
                </span>
            ) : (
                <InfoOutlinedIcon sx={{ m: "auto 5px", fontSize: "1rem" }} />
            )}
        </Tooltip>
    );
}

HelperTooltip.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element, // Add support for JSX elements
    ]).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]),
    placement: PropTypes.string,
};

HelperTooltip.defaultProps = {
    children: undefined,
    placement: "bottom",
};

export default HelperTooltip;
