import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";

import { AccountContext } from "../../user/Account";
import HelperTooltip from "../../../components/HelperTooltip";
import shouldDisplayPricing from "../../user/configurations/constraints";
import { ProjectContext } from "../../../context/ProjectContext";
import { UserType } from "../../../models/enum.ts";
import { hasApps } from "../../../utils/utils.ts";

import StyledDeploymentCostRenderer from "../../../styles/components/Billing/DeploymentCostRenderer.Styled";

function shouldDisplayCost(totalPerDay) {
    return totalPerDay !== null;
}

function DeploymentCostRenderer({ cpu, ram, gpu, storage }) {
    const [totalPerDay, setTotalPerDay] = useState(null);
    const [costBreakdownText, setCostBreakdownText] = useState("");

    const { userType, resourcesInfo, getUserConfiguration } =
        useContext(AccountContext); // this resource info is for all the resources consumed by this account
    const { resourcesInfo: curJobResourceInfo } = useContext(ProjectContext); // this resource info is only for the selected project
    const isAppsActive = hasApps(resourcesInfo, curJobResourceInfo);

    const calculateCost = (price, resource, total, breakDownText) => {
        const pricePerDay = (parseFloat(price) * 24).toFixed(2);
        const updatedTotal = total + parseFloat(pricePerDay);
        const description = `${resource}: $${price} x 24 hours = $${pricePerDay}`;
        const updatedBreakDownText = `${breakDownText}\n${description}`;
        return { updatedTotal, updatedBreakDownText };
    };

    useEffect(() => {
        try {
            setTotalPerDay(null);
            let total = 0;
            let breakDownText = "";

            const cpuToRamMapping =
                getUserConfiguration("cpuToRamMapping")[cpu];
            const cpuPrice = cpuToRamMapping.pricePerHour;
            if (shouldDisplayPricing("cpu", isAppsActive, cpu)) {
                const result = calculateCost(
                    cpuPrice,
                    "CPU",
                    total,
                    breakDownText
                );
                total = result.updatedTotal;
                breakDownText = result.updatedBreakDownText;
            }
            const { ramOptions } = cpuToRamMapping;
            const selectedRamOption = ramOptions.find(
                (ramOption) => ramOption[0] === ram
            );

            if (selectedRamOption) {
                const [, , ramPrice] = selectedRamOption;
                if (shouldDisplayPricing("ram", isAppsActive, ram)) {
                    const result = calculateCost(
                        ramPrice,
                        "RAM",
                        total,
                        breakDownText
                    );
                    total = result.updatedTotal;
                    breakDownText = result.updatedBreakDownText;
                }
            }

            // Check for GPU and add its price if available
            if (gpu) {
                const gpuMapping = getUserConfiguration("gpu")[gpu];

                if ("pricePerHour" in gpuMapping) {
                    const gpuPrice = gpuMapping.pricePerHour;
                    const result = calculateCost(
                        gpuPrice,
                        "GPU",
                        total,
                        breakDownText
                    );
                    total = result.updatedTotal;
                    breakDownText = result.updatedBreakDownText;
                }
            }

            // Check storage and add its price
            if (storage) {
                const storagePrice =
                    parseFloat(getUserConfiguration("storagePricePerGB")) *
                    parseFloat(storage);
                total += storagePrice;
                breakDownText += `\nPersistent Storage(monthly): $${storagePrice}
                    `;
            }

            if (total > 0) {
                setTotalPerDay(total.toFixed(2));
                // Add final total to the breakdown
                breakDownText += `\nTotal: $${total.toFixed(2)}

                This amount will be deducted from your
                credits if you have any.
            `;
                setCostBreakdownText(breakDownText);
            }
        } catch (err) {
            console.error("Error calculating costs:", err);
            setTotalPerDay(null);
        }
    }, [cpu, ram, gpu, storage, resourcesInfo, curJobResourceInfo]);

    return (
        (userType === UserType.PRO.value ||
            userType === UserType.TEAMS.value) &&
        shouldDisplayCost(totalPerDay) && (
            <StyledDeploymentCostRenderer>
                <InfoIcon color="info" className="InfoIcon" />
                <span data-testid="deploymentCost" className="Cost">
                    This deployment will cost you ${totalPerDay} per day
                </span>
                <HelperTooltip
                    text={costBreakdownText.split("\n").map((line, index) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                />
            </StyledDeploymentCostRenderer>
        )
    );
}

DeploymentCostRenderer.defaultProps = {
    cpu: "",
    ram: "",
    gpu: "",
    storage: "",
};

DeploymentCostRenderer.propTypes = {
    cpu: PropTypes.string,
    ram: PropTypes.string,
    gpu: PropTypes.string,
    storage: PropTypes.string,
};

export default DeploymentCostRenderer;
