import React, { useContext } from "react";
import PropTypes from "prop-types";
import { pink } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { AppContext, getAvatar } from "../context/AppContext";

function UserAvatar() {
    const { user, navigate } = useContext(AppContext);
    const userAvatar = getAvatar();
    const isAvatarUrl = userAvatar?.startsWith("blob:") || false;

    return (
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {isAvatarUrl && (
                <Avatar
                    data-testid="userAvatar"
                    className="cursor-pointer"
                    src={userAvatar}
                    onClick={() => {
                        navigate("/account");
                    }}
                />
            )}
            {!isAvatarUrl && (
                <Avatar
                    data-testid="userAvatar"
                    className="cursor-pointer"
                    sx={{ bgcolor: pink[500] }}
                    onClick={() => {
                        navigate("/account");
                    }}
                >
                    {user.fallbackAvatar}
                </Avatar>
            )}
        </Box>
    );
}

export default UserAvatar;
