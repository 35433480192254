import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "./UI/Form/inputs/Input";

// eslint-disable-next-line react/function-component-definition
const InputWithControls = React.forwardRef(
    (
        {
            value,
            id,
            placeholder,
            onValueUpdate,
            label,
            customInputControls,
            onDeleteValue,
            hideControls,
            editOnFocus,
            inputWidth,
            style,
        },
        ref
    ) => {
        const [inputValue, setInputValue] = useState(value);
        const [edit, setEdit] = useState(false);
        const textInput = ref || useRef(null);

        useEffect(() => {
            if (hideControls) {
                setEdit(true);
            }
        }, [hideControls]);

        useEffect(() => {
            setInputValue(value);
        }, [value]);

        const handleInputChange = (e) => {
            setInputValue(e.target.value);
            if (hideControls || editOnFocus) {
                onValueUpdate(e.target.value);
            }
        };

        function toggleEdit() {
            if (!edit) {
                textInput.current.focus();
            }
            setEdit(!edit);
        }

        function handleCancelEdit() {
            setInputValue(value);
            toggleEdit();
        }

        function handleSaveEdit() {
            onValueUpdate(inputValue);
            toggleEdit();
        }

        function renderActions() {
            let toRender = (
                <button type="button" onClick={toggleEdit}>
                    Edit
                </button>
            );

            const shouldDrawCustomActions = customInputControls.length > 0;
            if (shouldDrawCustomActions) {
                toRender = customInputControls.map((c, index) => (
                    <span key={new Date().getTime}>{c}</span>
                ));
            } else if (edit) {
                toRender = (
                    <>
                        <button type="button" onClick={handleSaveEdit}>
                            Save
                        </button>

                        <button type="button" onClick={handleCancelEdit}>
                            Cancel
                        </button>

                        {onDeleteValue ? (
                            <button
                                type="button"
                                onClick={() => {
                                    toggleEdit();
                                    onDeleteValue();
                                }}
                            >
                                Delete
                            </button>
                        ) : (
                            ""
                        )}
                    </>
                );
            }

            return toRender;
        }

        return (
            <div style={style}>
                <label htmlFor={id}>
                    {label ? <div className="label">{label}</div> : ""}
                    <div style={{ display: "flex" }}>
                        <Input
                            style={{ width: inputWidth }}
                            disabled={editOnFocus ? false : !edit}
                            ref={textInput}
                            type="text"
                            onChange={handleInputChange}
                            value={inputValue}
                            placeholder={placeholder}
                            id={id}
                        />
                        {hideControls && (
                            <div
                                style={{
                                    display: "flex",
                                    marginLeft: 4,
                                }}
                                className="Actions"
                            >
                                {renderActions()}
                            </div>
                        )}
                    </div>
                </label>
            </div>
        );
    }
);

InputWithControls.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    customInputControls: PropTypes.arrayOf(PropTypes.element),
    onValueUpdate: PropTypes.func,
    onDeleteValue: PropTypes.func,
    hideControls: PropTypes.bool,
    editOnFocus: PropTypes.bool,
    inputWidth: PropTypes.string,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};

InputWithControls.defaultProps = {
    hideControls: false,
    id: "",
    value: "",
    label: "",
    placeholder: "",
    customInputControls: [],
    onValueUpdate: () => {},
    onDeleteValue: undefined,
    editOnFocus: false,
    inputWidth: "50%",
    style: {},
};

export default InputWithControls;
