import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const StyleTextFieldWithLoader = styled(TextField)(({ theme }) => ({
    "& .MuiCircularProgress-root, & .MuiSvgIcon-root": {
        width: "1rem !important",
        height: "1rem !important",
    },
}));

export default StyleTextFieldWithLoader;
