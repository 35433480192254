import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as uuid from "uuid";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function BasicBreadcrumbs({ id, path }) {
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
        const links = path.map((p) => {
            const text = p;
            const index = path.indexOf(p);
            const subArray = path.slice(0, index);
            const href = `${subArray.join("/")}/${p}`;

            const link = {
                text,
                href,
            };

            // Disable click from the last element
            if (index === path.length - 1) {
                delete link.href;
            }

            return link;
        });

        setBreadcrumbs(links);
    }, [path]);

    return (
        <div role="presentation" id={id} data-testid={`breadcrumbs-${id}`}>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbs.map((link, index) => (
                    <Link
                        to={link.href}
                        key={link.text}
                        style={{
                            textTransform: index === 0 ? "capitalize" : "none",
                        }}
                    >
                        {link.text}
                    </Link>
                ))}
            </Breadcrumbs>
        </div>
    );
}

BasicBreadcrumbs.propTypes = {
    path: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.string,
};

BasicBreadcrumbs.defaultProps = {
    id: uuid.v4(),
};

export default BasicBreadcrumbs;
