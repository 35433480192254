import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ploomberAPI from "../../../services/ploomberAPI.ts";
import SidebarMenu from "../../../components/UI/SidebarMenu";
import Documentation from "../../../styles/components/Documentation.Styled";
import CodeBloc from "../../../components/CodeBloc";

function GithubDeploymentDocs({ open, setOpen, projectId }) {
    const [token, setToken] = useState("PLOOMBER_CLOUD_KEY");

    useEffect(() => {
        const displayToken = async () => {
            const tokenList = await ploomberAPI.listAPIKeys();
            if (tokenList && tokenList.length > 0) {
                setToken(tokenList[0].api_key);
            }
        };
        displayToken();
    }, []);

    const closeDoc = () => {
        setOpen(false);
    };

    return (
        <SidebarMenu open={open} onClose={closeDoc} className="fixedSize">
            <Documentation>
                <h2 className="title">Deploy from GitHub</h2>

                <div className="instruction">
                    <Typography>
                        This assumes you already have a repository with this
                        app&apos;s source code.
                    </Typography>
                </div>

                <div className="instruction">
                    <Typography>1. Install Ploomber Cloud CLI</Typography>
                    <CodeBloc>pip install ploomber-cloud</CodeBloc>
                </div>

                <div className="instruction">
                    <Typography>2. Set your API key</Typography>
                    <CodeBloc>ploomber-cloud key {token}</CodeBloc>
                </div>

                <div className="instruction">
                    <Typography>3. Set up GitHub secret</Typography>
                    <Typography>
                        Store your Ploomber Cloud API key as a GitHub secret
                        named <code>PLOOMBER_CLOUD_KEY</code> in your
                        repository.{" "}
                        <Link
                            to="https://docs.cloud.ploomber.io/en/latest/user-guide/github.html#set-github-secret"
                            target="_blank"
                            className="MuiButton-link"
                            rel="noopener noreferrer"
                        >
                            Read the docs
                        </Link>{" "}
                        to learn more.
                    </Typography>
                    <CodeBloc>{token}</CodeBloc>
                </div>
                <div className="instruction">
                    <Typography>
                        4. Download your ploomber-cloud.json config in your repo
                    </Typography>
                    <CodeBloc>
                        ploomber-cloud init --from-existing --only-config
                    </CodeBloc>
                </div>
                <div className="instruction">
                    <Typography>5. Set up GitHub Actions</Typography>
                    <CodeBloc>ploomber-cloud github</CodeBloc>
                    <Typography>
                        This will create a ploomber-cloud.yaml file in
                        .github/workflows/.
                    </Typography>
                </div>
                <div className="instruction">
                    <Typography>6. Commit and push the new files</Typography>
                    <CodeBloc>
                        git add ploomber-cloud.json
                        .github/workflows/ploomber-cloud.yaml <br />
                        git commit -m &apos;configure github actions
                        deployment&apos; <br />
                        git push
                    </CodeBloc>
                </div>
                <div className="instruction">
                    <Typography>7. Monitor deployment</Typography>
                    <Typography>
                        Check the Actions tab in your GitHub repository to
                        monitor the deployment progress.
                    </Typography>
                </div>
                <div className="CenteredContainer">
                    <Link
                        to="https://docs.cloud.ploomber.io/en/latest/user-guide/github.html"
                        target="_blank"
                        className="MuiButton-link"
                        rel="noopener noreferrer"
                    >
                        Read the documentation to learn more
                    </Link>
                </div>
            </Documentation>
        </SidebarMenu>
    );
}

GithubDeploymentDocs.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
};

export default GithubDeploymentDocs;
