import React, { useState } from "react";
import PropTypes from "prop-types";
import LabelsInput from "../../../components/UI/Form/inputs/LabelsInput";
import ControllerLabel from "../../../components/UI/ControllerLabel";
import StyledSetProjectLabelsController from "../../../styles/features/applications/controllers/SetProjectLabelsController.Styled";

function SetProjectLabelsController({
    labels,
    hideLabel,
    disabled,
    onChange,
    onEditButtonClick,
    disableEvents,
}) {
    return (
        <StyledSetProjectLabelsController
            className="Controller"
            data-testid="set-project-label"
        >
            {hideLabel === false && (
                <ControllerLabel text="Set labels" disableUserCheck />
            )}

            <LabelsInput
                disableEvents={disableEvents}
                disabled={disabled}
                defaultLabels={labels}
                onLabelAdd={(label) => {
                    const newLabels = [...labels];
                    newLabels.push(label);
                    onChange(newLabels);
                }}
                onLabelRemove={(labelToRemove) => {
                    const copyOfLabels = [...labels];
                    const newLabels = copyOfLabels.filter(
                        (label) => label !== labelToRemove
                    );
                    onChange(newLabels);
                }}
                onEditButtonClick={onEditButtonClick}
            />
        </StyledSetProjectLabelsController>
    );
}

SetProjectLabelsController.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string),
    hideLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onEditButtonClick: PropTypes.func,
    disableEvents: PropTypes.bool,
};

SetProjectLabelsController.defaultProps = {
    hideLabel: false,
    disabled: false,
    labels: [],
    onChange: (labels) => {},
    onEditButtonClick: undefined,
    disableEvents: false,
};

export default SetProjectLabelsController;
