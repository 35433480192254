import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

function BlockToggleButton({ title, checked, onClick, disabled, id }) {
    const itemId = id || title.replaceAll(" ", "").toLowerCase();

    const theme = useTheme();

    return (
        <div
            id={itemId}
            style={{
                width: "100%",
                padding: "10px 14px",
                border: checked
                    ? `1px solid ${theme.palette.neutral?.black}`
                    : `1px solid ${theme.palette.neutral?.darkGrey}`,
                borderRadius: 4,
                backgroundColor: disabled ? "rgba(0, 0, 0, 0.1)" : "inherit",
                color: "inherit",
                fontWeight: checked ? theme.fonts.weight?.semiBold : "inherit",
                display: "flex",
            }}
            role="button"
            disabled={disabled}
            onClick={(e) => {
                if (!disabled) {
                    onClick(e);
                } else {
                    alert("Disabled");
                }
            }}
            onKeyPress={(e) => {}}
            tabIndex="0"
        >
            <input
                type="radio"
                checked={checked}
                style={{
                    margin: "auto 0",
                    marginRight: 5,
                    accentColor: "#000",
                }}
                onChange={() => {}}
            />

            <label
                htmlFor={itemId}
                className="cursor-pointer"
                style={{ margin: "0" }}
            >
                {title}
            </label>
        </div>
    );
}

BlockToggleButton.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

BlockToggleButton.defaultProps = {
    disabled: false,
};

export default BlockToggleButton;
