import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ploomberAPI from "../../../services/ploomberAPI.ts";
import SidebarMenu from "../../../components/UI/SidebarMenu";
import Documentation from "../../../styles/components/Documentation.Styled";
import CodeBloc from "../../../components/CodeBloc";

function DownloadProjectDocs({ open, setOpen, projectId }) {
    const [token, setToken] = useState("PLOOMBER_CLOUD_KEY");

    useEffect(() => {
        const displayToken = async () => {
            const tokenList = await ploomberAPI.listAPIKeys();
            if (tokenList && tokenList.length > 0) {
                setToken(tokenList[0].api_key);
            }
        };
        displayToken();
    }, []);

    const closeDoc = () => {
        setOpen(false);
    };

    return (
        <SidebarMenu open={open} onClose={closeDoc} className="fixedSize">
            <Documentation>
                <h2 className="title">
                    Deploy this Project from Your Computer
                </h2>

                <div className="instruction">
                    <Typography>1. Install Ploomber Cloud CLI</Typography>
                    <CodeBloc>pip install ploomber-cloud</CodeBloc>
                </div>

                <div className="instruction">
                    <Typography>2. Set your API key</Typography>
                    <CodeBloc>ploomber-cloud key {token}</CodeBloc>
                </div>

                <div className="instruction">
                    <Typography>3. Download an existing project</Typography>
                    <CodeBloc>ploomber-cloud init --from-existing</CodeBloc>
                    <Typography>
                        Follow the prompts to select your project ({projectId}).
                    </Typography>
                </div>

                <div className="instruction">
                    <Typography>4. Edit your project</Typography>
                    <Typography>
                        Open the project directory in your favorite editor.
                    </Typography>
                </div>

                <div className="instruction">
                    <Typography>5. Deploy the project</Typography>
                    <CodeBloc>
                        cd {projectId}
                        <br />
                        ploomber-cloud deploy
                    </CodeBloc>
                </div>

                <div className="CenteredContainer">
                    <Link
                        to="https://docs.cloud.ploomber.io/en/latest/user-guide/cli.html"
                        target="_blank"
                        className="MuiButton-link"
                        rel="noopener noreferrer"
                    >
                        Read the documentation to learn more
                    </Link>
                </div>
            </Documentation>
        </SidebarMenu>
    );
}

DownloadProjectDocs.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
};

export default DownloadProjectDocs;
