import React from "react";
import PropTypes from "prop-types";

export default function KeyRenderer(props) {
    const { data } = props;
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
            }}
            data-testid={`${data.api_key}-grid-key`}
        >
            <div
                style={{
                    maxWidth: "95%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }}
            >
                <span>{data.api_key}</span>
            </div>
        </div>
    );
}

KeyRenderer.propTypes = {
    data: PropTypes.shape({
        api_key: PropTypes.string,
    }),
};

KeyRenderer.defaultProps = {
    data: {
        apiKey: "",
    },
};
