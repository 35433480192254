import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";

const StyledBlockTable = styled(Table)(({ theme }) => ({
    "& .MuiTableCell-root": {
        border: "none",
    },
    "& th": {
        textTransform: "capitalize",
        fontWeight: theme.fonts.weight.semiBold,
    },
    "& td": {
        wordBreak: "break-all",
        height: 60,
    },
    "& .inline-elements-container": {
        display: "inline-flex",
        gap: 50,
        width: "100%",
    },
}));

export default StyledBlockTable;
