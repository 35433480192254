import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCodeBloc = styled(Box)(({ theme }) => ({
    "&.bloc": {
        position: "relative",
        backgroundColor: "#f5f5f5",
        padding: "1rem",
        borderRadius: 5,
        marginTop: 0,
        overflow: "auto",
    },
    "& > .MuiIconButton-root": {
        position: "absolute",
        top: 8,
        right: 8,
    },
    "& > .text": {
        margin: 0,
        "& code": {
            fontFamily:
                'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
        },
    },
}));

export default StyledCodeBloc;
