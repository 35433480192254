import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Outlet } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import CodeIcon from "@mui/icons-material/Code";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ArticleIcon from "@mui/icons-material/Article";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import ExploreIcon from "@mui/icons-material/Explore";
import { useMediaQuery } from "@mui/material";
import AppBar from "../../../layouts/AppBar";
import Sidebar from "../../../layouts/Sidebar";
import { AppContext } from "../../../context/AppContext";
import telemetry from "../../../services/telemetry.ts";

function AuthorizedPage() {
    const appContext = useContext(AppContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { location } = appContext;
    const [openDrawer, setOpenDrawer] = useState(false);
    const [activePath, setActivePath] = useState([]);

    const sidebarLinks = useMemo(() => [
        {
            id: "applications",
            to: "/applications",
            icon: <DashboardCustomizeRoundedIcon height={20} />,
        },
        {
            id: "account",
            to: "/account",
            icon: <PersonIcon />,
        },
    ]);

    const sidebarBottomLinks = useMemo(
        () => [
            {
                id: "AI Editor",
                icon: <CodeIcon />,
                action: async () => {
                    window.open(`https://editor.ploomber.io`, "_blank");
                },
            },
            {
                id: "explore",
                icon: <ExploreIcon />,
                action: async () => {
                    window.open(
                        `${process.env.REACT_APP_URL}/explore`,
                        "_blank"
                    );
                },
            },
            {
                id: "documentation",
                icon: <ArticleIcon />,
                action: () => {
                    window.open("https://docs.cloud.ploomber.io");
                },
            },
            {
                id: "Get Support",
                icon: <HelpCenterIcon />,
                action: () => {
                    window.open(
                        "https://docs.cloud.ploomber.io/en/latest/quickstart/support.html"
                    );
                },
            },
            {
                id: "logout",
                icon: <LogoutIcon />,
                action: () => {
                    const { navigate, logout } = appContext;
                    telemetry.log("Logout");

                    logout();
                    navigate("/");
                },
            },
        ],
        [appContext]
    );

    const shouldOpenSidebar = (pathParts) => {
        // Close on mobile
        if (isMobile) {
            return false;
        }
        // Close on `create` `isApplicationStatus` and `isApplicationSettings`
        // prettier-ignore
        const closedConditions = [
            (parts) => parts.length === 2 && parts[0] === "applications" && parts[1] === "create",
            (parts) => parts.length === 3 && parts[0] === "applications",
            (parts) => parts.length === 4 && parts[0] === "applications" && parts[3] === "settings",
        ];
        return !closedConditions.some((condition) => condition(pathParts));
    };

    const updateActivePath = useCallback(() => {
        const { pathname } = location;
        const newActivePath = pathname.split("/").filter((s) => s);
        const shouldUpdate =
            JSON.stringify(newActivePath) !== JSON.stringify(activePath);
        const newOpenDrawer = shouldOpenSidebar(newActivePath);
        if (shouldUpdate) {
            setActivePath(newActivePath);
            setOpenDrawer(newOpenDrawer);
        }
    }, [location, activePath]);

    useEffect(() => {
        updateActivePath();
    }, [location, activePath]);

    useEffect(() => {
        setOpenDrawer(shouldOpenSidebar(activePath));
    }, [isMobile]);

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar activePath={activePath} open={openDrawer} />
            <Sidebar
                links={sidebarLinks}
                bottomLinks={sidebarBottomLinks}
                active={activePath}
                open={openDrawer}
            />

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                {/* Render nested route component */}
                <Outlet />
            </Box>
        </Box>
    );
}

export default AuthorizedPage;
