import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSelectOneGrid = styled(Grid)(({ theme }) => ({
    "& .grid-item": {
        "& img": {
            marginTop: "auto",
            transform: "scale(0.9)",
        },
    },
}));

export default StyledSelectOneGrid;
