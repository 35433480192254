import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Joyride from "react-joyride";

const buttonBase = {
    backgroundColor: "#fff",
    border: 0,
    borderRadius: 100,
    color: "#000",
    cursor: "pointer",
    fontSize: 12,
    lineHeight: 1,
    padding: 8,
    WebkitAppearance: "none",
};

const StyledCustomSkipButton = styled("div")({
    textDecoration: "underline",
    marginLeft: 5,
});

function CustomSkipButton() {
    return (
        <StyledCustomSkipButton>
            Click here to end tutorial
        </StyledCustomSkipButton>
    );
}

function StyledProductTour({ run, steps, callback }) {
    return (
        <Joyride
            run={run}
            steps={steps}
            callback={callback}
            disableScrolling
            locale={{
                skip: <CustomSkipButton />,
            }}
            disableCloseOnEsc
            disableOverlayClose
            showSkipButton
            hideCloseButton
            styles={{
                options: {
                    zIndex: 999999,
                    arrowColor: "#000",
                },
                overlay: {
                    zIndex: 99999,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
                tooltip: {
                    backgroundColor: "#000",
                    color: "#fff",
                    minWidth: 400,
                },
                tooltipTitle: {
                    padding: "5px 10px",
                    textAlign: "left",
                },
                tooltipContent: {
                    padding: "5px 10px",
                    textAlign: "left",
                },
                buttonBack: {
                    color: "#fff",
                    fontSize: 12,
                },
                buttonClose: {
                    color: "#fff",
                },
                buttonNext: {
                    ...buttonBase,
                },
                buttonSkip: {
                    color: "#fff",
                    fontSize: 12,
                },
            }}
        />
    );
}

StyledProductTour.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    callback: PropTypes.func.isRequired,
    run: PropTypes.bool.isRequired,
};

export default StyledProductTour;
