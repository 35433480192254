import React, { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { ProjectRecoveryView } from "./views";
import JoinSlackStickyButton from "./views/components/JoinSlackStickyButton";
import { parseErrorMessage } from "../../../utils/utils.ts";

function ProjectRecoveryPage(props) {
    const { updateSnackbarStatus } = useContext(AppContext);

    const getOriginalURLFromRedirect = () => {
        // Extract projectId from the originalURL
        const urlParams = new URLSearchParams(window.location.search);
        let originalURL = urlParams.get("originalURL");

        if (originalURL) {
            // Decode url if needed
            originalURL = decodeURIComponent(originalURL);
        } else {
            originalURL = "NOTFOUND";
        }

        return originalURL;
    };

    const originalURL = getOriginalURLFromRedirect();

    return (
        <div className="public-view-style">
            <ProjectRecoveryView
                originalURL={originalURL}
                onSuccess={(url) => {
                    setTimeout(
                        updateSnackbarStatus({
                            message:
                                "Application started successfully, redirecting",
                        }),
                        1000
                    );
                    window.location.href = originalURL;
                }}
                onFailure={(err) => {
                    updateSnackbarStatus({
                        message: parseErrorMessage(err),
                        severity: "error",
                    });
                }}
            />
        </div>
    );
}

export default ProjectRecoveryPage;
