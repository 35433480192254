import React, { useEffect, useMemo, useState } from "react";
import * as uuid from "uuid";
import PropTypes from "prop-types";
import BlockContext from "../../context/BlockContext";
import StyledBlock from "../../styles/components/Block/Block.Styled";

function Block(props) {
    const { open, children, border, id, hide } = props;
    const [openBlock, setOpenBlock] = useState(open);

    const blockProviderValue = useMemo(
        () => ({
            openBlock,
            setOpenBlock,
            children,
        }),
        [openBlock]
    );

    if (hide) {
        return "";
    }

    return (
        <BlockContext.Provider value={blockProviderValue}>
            <StyledBlock
                className="Block"
                component="div"
                border={border}
                data-testid={`block-${id}`}
                id={id}
            >
                {children}
            </StyledBlock>
        </BlockContext.Provider>
    );
}

Block.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
    open: PropTypes.bool,
    border: PropTypes.number,
    id: PropTypes.string,
    hide: PropTypes.bool,
};

Block.defaultProps = {
    open: true,
    border: undefined,
    id: uuid.v4(),
    hide: false,
};

export default Block;
