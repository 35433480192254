import React, { useState } from "react";
import { Popover } from "@mui/material";
import pricing from "./Pricing.json";

function PricingDetailsTable() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [noteText, setNoteText] = useState("");
    const [noteLink, setNoteLink] = useState("");
    const pricingDetails = pricing.details;

    const handlePopoverOpen = (event, key, header) => {
        // Stop event propagation to prevent tr's onMouseEnter from firing
        event.stopPropagation();
        const note = pricingDetails.notes[key]?.[header.toLowerCase()];
        if (note) {
            setNoteText(note.text);
            setNoteLink(note.link);
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = (event) => {
        // Stop event propagation to prevent tr's onMouseLeave from firing
        if (event) {
            event.stopPropagation();
        }
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);

    return (
        <div className="TableContainer">
            <table>
                <thead>
                    <tr>
                        <th aria-label="Features" />
                        {pricingDetails.headers.map((header, i) => (
                            <th key={i}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {pricingDetails.rows.map((row, i) => (
                        <tr key={i}>
                            <td>{row.label}</td>
                            {pricingDetails.headers.map((header, j) => {
                                const headerLower = header.toLowerCase();
                                const value =
                                    pricingDetails.plans[headerLower]?.[row.id];
                                const note =
                                    pricingDetails.notes[row.id]?.[headerLower];

                                let displayValue = "Custom";
                                if (typeof value === "boolean") {
                                    displayValue = value ? "Yes" : "No";
                                } else if (value !== undefined) {
                                    displayValue = value;
                                }

                                let cursor = "default";
                                if (note) {
                                    cursor = note.link ? "pointer" : "help";
                                }

                                return note?.link ? (
                                    <td key={j}>
                                        <a
                                            href={note.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                textDecoration: "none",
                                                color: "inherit",
                                                cursor,
                                                display: "block",
                                                width: "100%",
                                                height: "100%",
                                                position: "relative",
                                            }}
                                            onMouseEnter={(e) =>
                                                handlePopoverOpen(
                                                    e,
                                                    row.id,
                                                    header
                                                )
                                            }
                                            onMouseLeave={handlePopoverClose}
                                        >
                                            {displayValue}
                                        </a>
                                    </td>
                                ) : (
                                    <td
                                        key={j}
                                        style={{ cursor, position: "relative" }}
                                        onMouseEnter={(e) =>
                                            handlePopoverOpen(e, row.id, header)
                                        }
                                        onMouseLeave={handlePopoverClose}
                                    >
                                        {displayValue}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
            <Popover
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={() => handlePopoverClose()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                disableRestoreFocus
                sx={{
                    pointerEvents: "none",
                }}
            >
                <div
                    style={{
                        padding: "8px",
                        backgroundColor: "#f5f5f5",
                    }}
                >
                    <span>{noteText}</span>
                    {noteLink && <strong> Click to learn more</strong>}
                </div>
            </Popover>
        </div>
    );
}

export default PricingDetailsTable;
