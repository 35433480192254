import React, { useContext } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AppContext } from "../../../context/AppContext";

export default function InputWithCopy({ input, textToCopy, hideValue, id }) {
    const { updateSnackbarStatus } = useContext(AppContext);
    return (
        <div>
            <div
                id={id}
                className="InputWithCopy"
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <div
                    className="InputContainer"
                    style={{ margin: "auto 0", minWidth: "20%" }}
                >
                    {input}
                </div>
                <IconButton
                    disableRipple
                    onClick={() => {
                        navigator.clipboard.writeText(textToCopy);
                        const message = hideValue
                            ? "Copied to clipboard"
                            : `${textToCopy} copied to clipboard`;
                        updateSnackbarStatus({
                            message,
                        });
                    }}
                >
                    <ContentCopyIcon fontSize="small" sx={{ m: "auto 0" }} />
                </IconButton>
            </div>
        </div>
    );
}

InputWithCopy.propTypes = {
    input: PropTypes.element.isRequired,
    textToCopy: PropTypes.string,
    hideValue: PropTypes.bool,
    id: PropTypes.string,
};

InputWithCopy.defaultProps = {
    textToCopy: "",
    hideValue: false,
    id: "",
};
