import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCreditUsage = styled(Grid)(({ theme }) => ({
    marginTop: 0,

    "& .Spinner": {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
    },

    "& .BillingGrid": {
        marginBottom: "1rem",
        marginTop: -25,
        display: "inline-flex",
        width: "100%",
    },

    "& .BillingDue": {
        width: "100%",
        margin: "auto 0",
    },

    "& .ActionButton": {
        margin: "auto 0px",
    },

    "& .CreditsCard": {
        width: "50%",
        padding: "1.25rem 1.875rem",
        marginTop: "1rem",
        marginBottom: "2rem",
    },

    "& .Tooltip": {
        fontSize: "0.1rem",
        display: "flex",
        alignItems: "center",
    },

    "& .CreditsBar": {
        fontWeight: "bold",
        marginBottom: "1rem",
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
    },

    "& .CreditsBarContainer": {
        display: "inline-flex",
        width: "100%",
    },
}));

export default StyledCreditUsage;
