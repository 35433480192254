import { styled } from "@mui/material/styles";

const KeyValWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    gap: "50px",
}));

export default KeyValWrapper;
