import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledLoader = styled(Box)(({ theme }) => ({
    color: theme.palette.neutral.grey,
    "&": {
        position: "fixed",
        zIndex: 999999,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        overflow: "hidden",
    },
    "& .Container *": {
        color: theme.palette.neutral.white,
    },
    "& .Container": {
        textAlign: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        fontSize: "2rem",
    },
}));

export default StyledLoader;
