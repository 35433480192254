import React, { useState, useContext } from "react";
import {
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import PropTypes from "prop-types";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AppContext } from "../../context/AppContext";

function InputWithValidation({
    id,
    label,
    disabled,
    value,
    placeholder,
    validator,
    onChange,
    type,
}) {
    const { updateSnackbarStatus } = useContext(AppContext);

    const [touched, setTouched] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleBlur = () => {
        setTouched(true);
        const errorMessage = validator(value);

        if (errorMessage) {
            updateSnackbarStatus({
                message: errorMessage,
                severity: "error",
            });
        }

        setIsValid(!errorMessage);
    };

    return (
        <>
            <InputLabel htmlFor={id} shrink>
                {label}
            </InputLabel>
            <OutlinedInput
                id={id}
                type={type === "password" && showPassword ? "text" : type}
                error={touched && !isValid}
                disabled={disabled}
                onChange={onChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                autoComplete="new-password"
                value={value}
                endAdornment={
                    type === "password" && (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    )
                }
            />
        </>
    );
}

InputWithValidation.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    validator: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
};

InputWithValidation.defaultProps = {
    type: "text",
};

export default InputWithValidation;
