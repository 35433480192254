import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledLogsTabs = styled(Box)(({ theme }) => ({
    "&": {
        width: "100%",
        backgroundColor: "inherit",
    },
}));

export default StyledLogsTabs;
