import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import { ActionButton } from "../../../../components/UI/index";
import DeleteCustomDomainDialog from "../../../../components/DeleteCustomDomainDialog";
import { CertificateStatus } from "../../../../models/enum.ts";
import HelperTooltip from "../../../../components/HelperTooltip";
import StyledCustomDomainRecord from "../../../../styles/features/dashboards/controllers/CustomDomainRecord.Styled";
import ShowDNSInstructionsForNameserversMethod from "./ShowDNSInstructionsForNameserversMethod";
import ShowDNSInstructionsForPointingMethod from "./ShowDNSInstructionsForPointingMethod";
import CustomDomainContext from "./CustomDomainContext";

function ShowCustomDomainDNSStatus() {
    const { domain } = useContext(CustomDomainContext);

    return (
        <Box className="RowItem DNSStatus">
            {domain.certificate.status === CertificateStatus.ISSUED ? (
                <div className="DomainIsReady">Ready</div>
            ) : (
                <div className="ActionRequired">
                    {domain.nameservers_updated
                        ? "Pending certification approval (nameservers updated successfully)"
                        : "DNS update required"}
                </div>
            )}
        </Box>
    );
}

const ConnectionMethods = {
    Pointing: "pointing",
    Nameservers: "nameservers",
};

function CustomDomainRecord({ domain, onDomainDelete, id, domains }) {
    const [connectionMethod, setConnectionMethod] = useState(
        domain.nameservers_updated
            ? ConnectionMethods.Nameservers
            : ConnectionMethods.Pointing
    );

    const [openNsDetails, setOpenNsDetails] = useState(false);
    const [openDeleteCustomDialog, setOpenDeleteCustomDialog] = useState(false);

    useEffect(() => {
        setOpenNsDetails(
            domain.certificate.status !== CertificateStatus.ISSUED
        );
    }, []);

    const customDomainContextValue = useMemo(
        () => ({
            domain,
        }),
        []
    );

    return (
        <CustomDomainContext.Provider value={customDomainContextValue}>
            <StyledCustomDomainRecord
                id={`CustomDomainRecord-${id}`}
                data-testid={`CustomDomainRecord-${id}`}
                className="CustomDomainRecord ControllerItem"
            >
                <Box className="ExpandableRow">
                    <Box className="RowItem">
                        <button
                            className="OpenNSDetailsButton"
                            type="button"
                            onClick={() => {
                                setOpenNsDetails(!openNsDetails);
                            }}
                        >
                            {openNsDetails ? <RemoveIcon /> : <AddIcon />}
                        </button>
                    </Box>

                    <Box className="RowItem">{domain.name}</Box>

                    <ShowCustomDomainDNSStatus />
                </Box>

                <Box
                    className={`ExpansionContainer ${
                        !openNsDetails && "Closed"
                    }`}
                >
                    <Box className="DNSStatusContainer NSStatusContainer">
                        <div className="SelectDNSConnectionMethodContainer">
                            Follow the instructions to connect your domain.
                            <span className="ConnectionMethod">
                                If you prefer, you can connect by{" "}
                                <span
                                    role="button"
                                    tabIndex="0"
                                    className="Link"
                                    onClick={() => {
                                        const newConnectionMethod =
                                            connectionMethod ===
                                            ConnectionMethods.Nameservers
                                                ? ConnectionMethods.Pointing
                                                : ConnectionMethods.Nameservers;
                                        setConnectionMethod(
                                            newConnectionMethod
                                        );
                                    }}
                                    onKeyDown={(e) => {}}
                                >
                                    {connectionMethod ===
                                    ConnectionMethods.Nameservers
                                        ? "pointing"
                                        : "nameservers"}
                                </span>
                                .
                            </span>
                        </div>

                        <HelperTooltip text={`Delete ${domain?.name}`}>
                            <ActionButton
                                variant="contained"
                                color="error"
                                id="openDeleteDialogButton"
                                onClick={() => {
                                    setOpenDeleteCustomDialog(true);
                                }}
                            >
                                Delete
                            </ActionButton>
                        </HelperTooltip>
                    </Box>

                    <Box className="DNSInstructions">
                        <Box className="NSDetailsContainer">
                            {connectionMethod ===
                            ConnectionMethods.Nameservers ? (
                                <ShowDNSInstructionsForNameserversMethod
                                    domain={domain}
                                />
                            ) : (
                                <ShowDNSInstructionsForPointingMethod
                                    domain={domain}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>

                <DeleteCustomDomainDialog
                    open={openDeleteCustomDialog}
                    onDelete={onDomainDelete}
                    onClose={() => {
                        setOpenDeleteCustomDialog(false);
                    }}
                    domainName={domain.name}
                    domains={domains}
                />
            </StyledCustomDomainRecord>
        </CustomDomainContext.Provider>
    );
}

CustomDomainRecord.propTypes = {
    domain: PropTypes.shape({
        name: PropTypes.string,
        status: PropTypes.string,
        ns_details: PropTypes.arrayOf(PropTypes.string),
        certificate: PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
            status: PropTypes.string,
        }),
        dns_records: PropTypes.shape({
            type: PropTypes.string,
            host: PropTypes.string,
            value: PropTypes.string,
        }),
        nameservers_updated: PropTypes.bool,
    }).isRequired,
    onDomainDelete: PropTypes.func,
    id: PropTypes.string.isRequired,
    domains: PropTypes.arrayOf(PropTypes.string),
};

CustomDomainRecord.defaultProps = {
    onDomainDelete: () => {},
    domains: [],
};

export default CustomDomainRecord;
