import React, { useEffect, useContext } from "react";
import telemetry from "../../../services/telemetry.ts";
import { SigninView } from "./views";
import { AppContext } from "../../../context/AppContext";
import { parseErrorMessage } from "../../../utils/utils.ts";

export const isValidPloomberUrl = (url) => {
    try {
        const urlObj = new URL(url);
        if (
            process.env.REACT_APP_BASE_URL.includes("-dev") ||
            process.env.REACT_APP_BASE_URL.includes("localhost")
        ) {
            return true;
        }
        return (
            urlObj.hostname.endsWith(".ploomber.io") ||
            urlObj.hostname === "ploomber.io"
        );
    } catch {
        return false;
    }
};

function SigninPage(props) {
    const { navigate, location, updateSnackbarStatus } = useContext(AppContext);

    useEffect(() => {
        telemetry.log(telemetry.Events.PageView, { includeUserDetails: false });
    }, []);

    const handleSignIn = () => {
        const searchParams = new URLSearchParams(location.search);
        const redirect = searchParams.get("redirect");
        const defaultReturnUrl = searchParams.get("returnUrl") || "/";
        if (redirect && isValidPloomberUrl(decodeURIComponent(redirect))) {
            window.location.href = decodeURIComponent(redirect);
        }
        navigate(defaultReturnUrl);
    };

    return (
        <div className="public-view-style">
            <SigninView
                onSuccess={handleSignIn}
                onFailure={(err) => {
                    updateSnackbarStatus({
                        message: parseErrorMessage(err),
                        severity: "error",
                    });
                }}
            />
        </div>
    );
}

export default SigninPage;
